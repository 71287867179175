import React from 'react';

import {
  Container, Row, Col,
  Button,
  Card,
} from 'reactstrap';

import { withRouter } from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import { get_clubdata, join_club } from 'utils/api.js';

import 'pages/user/League.css';

class ClubPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      league: null,
    };

    this.fetchLeagueData = this.fetchLeagueData.bind(this);
    this.joinClub = this.joinClub.bind(this);
  }

  componentDidMount() {
    this.fetchLeagueData();
  }

  componentWillReceiveProps(nextProps) {
    this.fetchLeagueData();
  }

  fetchLeagueData() {
    const { clubname } = this.props.match.params;

    get_clubdata(clubname,
    ({success, member, league, ...data}) => {

      if (member) {
        this.props.history.push('/league/'+league)
      }

      /*if (!member && !data.emailPresent){
        logout((response) => {
            this.props.loggedOutCallback();
            this.props.history.push("/");
          }, (error) => {
            console.log(error);
            console.log("Error occurred trying to logout!");
        })
      }*/

      this.setState({
        error: undefined,
        league,
        openMembership: data.openmembership,
        leagueTitle: data.leaguename,
        leagueOwner: data.leagueowner,
        emailPresent: data.emailPresent,
        leagueOwnerEmail: data.leagueOwnerEmail,
      });
    }, (error) => {
      this.setState({error: true});
    });
  }

  joinClub(event) {
    if (!this.state.emailPresent){
      this.props.history.push('/profile/settings');
      return;
    }
    const { clubname } = this.props.match.params;

    join_club(clubname,
    ({success, league, me}) => {
      this.props.updateMe(me);
      this.props.history.push('/league/'+league)
    }, (error) => {
      console.log(error);
    });
  }

  render() {
    if (this.state.error) {
      return (
        <Container fluid className="px-0 text-center" style={{backgroundColor:"rgba(236, 240, 241, 0.5)", minHeight: "100vh", paddingTop:"5.5rem", paddingBottom:"2rem"}}>
          <Row className="d-flex justify-content-center pt-4 px-4">
            <Col lg="7" className="text-center">
              <Card body className="text-center p-5 mt-4">
                <h5 style={{fontWeight:"bolder"}}>Unknown Club</h5>
                <div style={{color:"#999"}}>
                  Check with your club for the correct address to use.
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }
    if (this.state.league === null) {
      return (
        <Container fluid className="px-0 text-center" style={{backgroundColor:"rgba(236, 240, 241, 0.5)", minHeight: "100vh", paddingTop:"5.5rem", paddingBottom:"2rem"}}>
          <div className="pt-5">
            <FontAwesome name="spinner" size="4x" spin />
          </div>
        </Container>
      )
    }

    return (
      <Container fluid className="px-0" style={{backgroundColor:"rgba(236, 240, 241, 0.5)", minHeight: "100vh", paddingTop:"5.5rem", paddingBottom:"2rem"}}>
        <div className="px-lg-4">
          {/*<LeagueTitle {...this.state} {...this.props}/>*/}
          {this.state.openMembership
            ? <OpenMembership {...this.state} {...this.props} joinClub={this.joinClub} />
            : <ClosedMembership {...this.state} {...this.props} />}
        </div>
      </Container>
    );
  }
}

{/*
const LeagueTitle = (props) => (
  <Row className="d-flex justify-content-center px-4">
    <Col lg="7" className="d-flex justify-content-between align-items-start">
      <span>
        <h3 style={{fontWeight:"bolder"}}>{props.leagueTitle}</h3>
        {!props.leagueOwner ? undefined :
        <div style={{color:"#aaa", fontWeight:"200", fontSize: "0.9rem"}}>
          {'Commissioner ' + props.leagueOwner}
        </div>
        }
        {!props.leagueOwnerEmail ? undefined :
        <a href={"mailto:"+props.leagueOwnerEmail}>
          <span style={{whiteSpace: "nowrap", fontSize: "0.85rem"}}>
            <FontAwesome name="envelope" className="mr-2" />
            <span>{props.leagueOwnerEmail}</span>
          </span>
        </a>
        }
      </span>
    </Col>
  </Row>
)*/}

const OpenMembership = (props) => (
  <Row className="d-flex justify-content-center px-4">
  <Col lg="7">
    <Card body className="text-center p-5 mt-4">
      <div className="">

          <div className="joinContent_title">Welcome to the league</div>
          <h2 className="joinContent_leaguename">{props.leagueTitle}</h2>

          <div>
            <Row className="justify-content-center text-right p-4">
              <Col xs="10" sm="9" md="8" lg="6" xl="5">
                  <div justify-content-center>
                    <div>
                      <Button color="success" onClick={props.joinClub}block> Join Now </Button>
                    </div>
                  </div>
                  {!props.emailPresent  &&
                    <div style={{color:"red", fontWeight:"300", fontSize: "0.9rem"}} className="mt-2">
                      {'Sorry to trouble you, but you must complete your profile before playing in this contest.'}
                    </div>
                  }
              </Col>
            </Row>
            {props.leagueOwnerEmail &&
              <Row noGutters className="justify-content-center">
                <Col xs="10" sm="9" md="9" lg="9" xl="8">
                  <div className="mt-3 text-center d-md-flex justify-content-center">
                    Commissioner:
                    <a href={"mailto:"+props.leagueOwnerEmail}>
                      <div style={{whiteSpace: "nowrap", fontSize: "0.85rem", paddingLeft:'10px'}}>
                        <FontAwesome name="envelope" className="mr-2" />
                        <span>{props.leagueOwnerEmail}</span>
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            }
          </div>
      </div>
    </Card>
  </Col>
  </Row>
)

const ClosedMembership = (props) => (
  <Row className="d-flex justify-content-center pt-4 px-4">
    <Col lg="7" className="text-center">
      <Card body className="text-center p-5 mt-4">
        <div className="joinContent_title">Welcome to the league</div>
        <h2 className="joinContent_leaguename">{props.leagueTitle}</h2>
        <h5 style={{fontWeight:"bolder"}}>Private Invitation Only</h5>
        <div style={{color:"#999"}}>
          Contact the league commissioner to join this golf pool.
        </div>

        {props.leagueOwnerEmail &&
          <Row noGutters className="justify-content-center">
            <Col xs="10" sm="9" md="9" lg="9" xl="8">
              <div className="mt-3 text-center">
                Commissioner:
                <a href={"mailto:"+props.leagueOwnerEmail}>
                  <span style={{whiteSpace: "nowrap", fontSize: "0.85rem", paddingLeft:'10px'}}>
                    <FontAwesome name="envelope" className="mr-2" />
                    <span>{props.leagueOwnerEmail}</span>
                  </span>
                </a>
              </div>
            </Col>
          </Row>
        }
      </Card>
    </Col>
  </Row>
)

export default withRouter(ClubPage);
