import React from 'react';
import Linkify from 'react-linkify';

import {
  Card, CardHeader, CardBody, CardSubtitle, CardText,
  Table,
  Badge,
  Collapse,
  Button, Row, Col,
  Modal, ModalBody, ModalHeader,
  Alert,
} from 'reactstrap';
import {Link,} from 'react-router-dom';

import Select from 'react-select';

import FontAwesome from 'react-fontawesome';
import { getTextWidth } from 'get-text-width';

import LoadingModal from 'common/LoadingModal.js';
import GolferModal from 'common/GolferModal.js';
import LeagueContestOADFields from 'pages/user/LeagueContestOADFields.js';
import LeagueBalanceWarning from 'common/LeagueBalanceWarning';

import numeral from 'numeral';

import { isMobPortrait, } from 'utils/responsive.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';


import {
  get_leagueContestOAD, save_lc_oad_entry, get_leagueContestOADBreakdown
} from 'utils/api.js';
import { CURRENT_SEASON, assignRankToLeaderboard } from 'utils/constants';


export default class LeagueContestOAD extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      season: props.season?props.season:CURRENT_SEASON(),
      leagueContest: null,
      contesttype: props.contesttype,
      golferModalOpen: false,
      golferModalGolferId: null,
      error: null,
      isPrevPicksOpen: {},
      selectedGolferId: null,
      selectedTeamIndx: 0,
      selectedEntryIndx: 0,
      selectedGolferIds: [],
      selectedGType: 'REG',
      dataRetrieveError: null,
    };

  }


  componentDidMount() {
    this.fetchLeagueContestData(this.props.activeLeague, this.props.season);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeLeague !== this.props.activeLeague ||
      nextProps.season !== this.props.season || nextProps.contesttype !== this.props.contesttype) {
      this.fetchLeagueContestData(nextProps.activeLeague, nextProps.season);
    }else if (nextProps.activeLeague === this.props.activeLeague &&
        nextProps.season === this.props.season && nextProps.contesttype === this.props.contesttype &&
        nextProps.passedEventid !== (this.props.passedEventid||0)){
          let paramEvent = nextProps.passedEventid;
          if (nextProps.showLB && paramEvent && this.state.leagueContest && this.state.leagueContest.leaderboard && this.state.leagueContest.leaderboard.length > 0){
            //const found = this.state.leagueContest.leaderboard.find(ele => ele.event_id === parseInt(paramEvent));
            //if (found){
              this.props.toggleShowLCLeaderboard(parseInt(paramEvent),this.state.leagueContest.leagueContestId);
            //}
          }
      }
  }

  fetchLeagueContestData = (league, season) => {
    this.setState({season:season, dataRetrieveError: null});
    if (!league || !season){return;}
    get_leagueContestOAD(league, season,
     ({success, error, leagueContestPresent, leagueContest}) => {
       if (!success){
         this.setState({
           isLoading:false, dataRetrieveError: error
         });
         return;
       }

       if (leagueContest && leagueContest.restrictView){
         this.props.history.push('/league/'+league);
         return;
       }


       if (leagueContest && leagueContest.nextEvent && leagueContest.nextEvent.teams.length > 0){
         leagueContest.nextEvent.teams.forEach((team, tIndx)=>{
           if (team.entries.length < leagueContest.noGolfersPerWk){
             for (var ii = team.entries.length; ii < leagueContest.noGolfersPerWk; ii++){
               team.entries.push( {
                 "id": 0,
                 "golferid": 0,
                 "pickGolferid": 0,
                 "pickAltGolferid": 0
               });
             }
           }
         });
       }

        if (leagueContest && leagueContest.nextEvent){
          leagueContest.nextEvent.availableGolfers.sort((a, b)=>{
            return a.golfer_name.toUpperCase() < b.golfer_name.toUpperCase() ? -1 : 1;
          });
          //if (leagueContest.nextEvent.teams.length > 0){
          //  leagueContest.nextEvent.teams[0].entries.sort((a,b)=>{return (a.id===0?99999999999:a.id)-(b.id===0?99999999999:b.id)});
          //}
        }

        let paramEvent = this.props.passedEventid;
        if (this.props.showLB && paramEvent && leagueContest.leaderboard && leagueContest.leaderboard.length > 0){
          //const found = leagueContest.leaderboard.find(ele => ele.event_id === parseInt(paramEvent));
          //if (found){
            this.props.toggleShowLCLeaderboard(parseInt(paramEvent),leagueContest.leagueContestId);
          //}
        }

        let isPrevPicksOpen = {};
        if (leagueContest && leagueContest.prevPicks){
          const distinctTeamNames = [...new Set(leagueContest.prevPicks.map(item => item.team_name))];
          isPrevPicksOpen = distinctTeamNames.reduce((acc, value) => {
            acc[value] = false;
            return acc;
          }, {});
        }

        this.setState({
          isLoading:false, leagueContest, dataRetrieveError: null, isPrevPicksOpen
        });

    }, (error) => {
      console.log(error);
      this.setState({
        isLoading:false, leagueContest: null
      });
    });
  }


  addTeamEntry = (contest) =>{
    let leagueContest = this.state.leagueContest;
    if (!leagueContest || !leagueContest.nextEvent){return}


    if (leagueContest.maxTeamsPerUser > leagueContest.nextEvent.teams.length){
      let teamId = leagueContest.nextEvent.teams.length;
      //either team is empty or golfer is empty -
      let stopAnotherTeamCreate = false;
      if (leagueContest.nextEvent.teams.length > 0 && leagueContest.nextEvent.teams[teamId-1].id === 0){
        stopAnotherTeamCreate = true;
      }else if (leagueContest.nextEvent.teams.length > 0 && leagueContest.nextEvent.teams[teamId-1].id > 0){
        let entries = leagueContest.nextEvent.teams[teamId-1].entries;
        entries.forEach((e)=>{if(e.pickGolferid === 0){stopAnotherTeamCreate = true;}});
      }
      if (stopAnotherTeamCreate){
        confirmAlert({
          title: 'Alert',
          message: 'Please complete the existing entry before adding another.',
          buttons: [
            {
              label: 'OK',
              onClick: () => { }
            }
          ]
        });
        return;
      }

      const add_new_team = ()=>{
        leagueContest.nextEvent.teams.push( {
          "id": 0,
          "name": '' +  (teamId + 1),
          "entries": []
        });

        if (leagueContest.nextEvent.teams[teamId].entries.length < leagueContest.noGolfersPerWk){
            for (var ii = leagueContest.nextEvent.teams[teamId].entries.length; ii < leagueContest.noGolfersPerWk; ii++){
              leagueContest.nextEvent.teams[teamId].entries.push( {
                "id": 0,
                "golferid": 0,
                "pickGolferid": 0,
                "pickAltGolferid": 0
              });
            }
        }
        this.setState({leagueContest});
        if (contest){
          this.saveTeamEntries(leagueContest.nextEvent.teams);
        }
      }

      if (teamId > 0){
        confirmAlert({
          title: 'Alert',
          message: 'Are you sure you want to create another entry? You’ll be responsible for multiple picks each week and probably additional entry fees.',
          buttons: [
            {
              label: 'Yes',
              onClick: () => { add_new_team();}
            },
            {
              label: 'Cancel',
              onClick: () => { }
            }
          ]
        });
      }else{
        add_new_team();
      }

    }
  }

  saveTeamEntries = (teams) => {
    this.setState({isLoading:true, error:''});
    let leagueContest = this.state.leagueContest;
    save_lc_oad_entry(this.props.activeLeague, leagueContest.nextEvent.contestEventid, teams,
     ({success, error, teams, allTeams, prevPicks}) => {
       if (success){
        let leagueContest = this.state.leagueContest;
        leagueContest.nextEvent.teams = teams;
        leagueContest.nextEvent.allTeams = allTeams;
        leagueContest.prevPicks = prevPicks;

        const distinctTeamNames = [...new Set(prevPicks.map(item => item.team_name))];
        const isPrevPicksOpen = distinctTeamNames.reduce((acc, value) => {
          acc[value] = true;
          return acc;
        }, {});
        if (leagueContest.nextEvent.teams.length > 0){
          leagueContest.nextEvent.teams.forEach((team, tIndx)=>{
            if (team.entries.length < leagueContest.noGolfersPerWk){
              for (var ii = team.entries.length; ii < leagueContest.noGolfersPerWk; ii++){
                team.entries.push( {
                  "id": 0,
                  "golferid": 0,
                  "pickGolferid": 0,
                  "pickAltGolferid": 0
                });
              }
            }
          });
        }

        //leagueContest.nextEvent.teams[0].entries.sort((a,b)=>{return (a.id===0?99999999999:a.id)-(b.id===0?99999999999:b.id)});
        this.setState({isLoading:false, leagueContest, isPrevPicksOpen});
      }else{
        if (error === 'Late entry. The tournament has already started.'){
          let lc = this.state.leagueContest;
          lc.nextEvent.status = 'round1';
          this.setState({isLoading:false, error, leagueContest: lc});
        }else{
          this.setState({isLoading:false, error,});
        }
      }

    }, (error) => {
      console.log(error);
      this.setState({
        leagueContest: null
      });
    });
  }

  setSeason = (season) => {
    this.fetchLeagueContestData(this.props.activeLeague, season);
  }

  toggleGolferModal = () => {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }

  showGolferDetails = (golferid) => {
    if (!golferid) return;
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }

  selectGolfer = (golferid, teamIndx, entryIndx) => {
    //let leagueContest = this.state.leagueContest;
    //leagueContest.nextEvent.teams[teamIndx].entries[entryIndx].golferid = golferid;
    let teams = JSON.parse(JSON.stringify(this.state.leagueContest.nextEvent.teams));
    if (this.state.selectedGType === 'ALT'){
      teams[teamIndx||this.state.selectedTeamIndx].entries[entryIndx||this.state.selectedEntryIndx].pickAltGolferid = golferid;
    }else{
      teams[teamIndx||this.state.selectedTeamIndx].entries[entryIndx||this.state.selectedEntryIndx].golferid = golferid;
      teams[teamIndx||this.state.selectedTeamIndx].entries[entryIndx||this.state.selectedEntryIndx].pickGolferid = golferid;
    }

    //this.setState({teams});
    this.saveTeamEntries(teams);
  }

  togglePrevPicksOpen = (team)=>{
    const isPrevPicksOpen = this.state.isPrevPicksOpen;
    isPrevPicksOpen[team] = !isPrevPicksOpen[team];
    this.setState({isPrevPicksOpen});
  }

  toggleLoading = (isLoading) =>{
    this.setState({isLoading:isLoading})
  }

  toggleViewFields = (event, selectedTeamIndx, selectedEntryIndx, selectedGolferIds, selectedGType) => {
    const eventStatus = this.state.leagueContest.nextEvent.status;
    if (eventStatus !== 'open'){return;}
    if (!this.state.isViewFields){
      this.setState({isViewFields: !this.state.isViewFields, selectedTeamIndx, selectedEntryIndx, selectedGolferIds, selectedGType});
    }else{
      this.setState({isViewFields: !this.state.isViewFields});
    }
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  render() {
    if (this.state.leagueContest && this.state.leagueContest.restrictView){
      return(
        <div className="text-center text-danger font-weight-bold">{'Low Balance, reach to the Commissioner'}</div>
      )
    }
    if (this.state.dataRetrieveError){
      return(
        <div className="text-center text-danger font-weight-bold">{this.state.dataRetrieveError}</div>
      );
    }

    if (this.state.isViewFields){
      return (
        <LeagueContestOADFields activeLeague={this.props.activeLeague}
          leaguecontestId={this.state.leagueContest.leagueContestId}
          contesteventId={this.state.leagueContest.nextEvent.contestEventid}
          toggleViewFields={this.toggleViewFields}
          selectGolfer={this.selectGolfer}
          selectedGolferIds={this.state.selectedGolferIds}
          event={this.state.leagueContest.nextEvent}
        />
      )
    }
    return (
      <>
          <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />

          <Header toggleShowAllLeagueConest={this.props.toggleShowAllLeagueConest} />
          <BalanceWarning activeLeague={this.props.activeLeague} balancewarning={this.state.leagueContest?this.state.leagueContest.balancewarning:null}/>

          <OADAlert oadMsg={this.state.leagueContest && this.state.leagueContest.msg} />

          <NextEvent activeLeague={this.props.activeLeague}
            leagueContest={this.state.leagueContest}
            error={this.state.error}
            selectGolfer={this.selectGolfer}
            showGolferDetails={this.showGolferDetails}
            saveTeamEntries={this.saveTeamEntries}
            toggleViewFields={this.toggleViewFields}
            addTeamEntry={this.addTeamEntry}/>

          <PrevPicks activeLeague={this.props.activeLeague}
            leagueContest={this.state.leagueContest}
            isPrevPicksOpen={this.state.isPrevPicksOpen}
            showGolferDetails={this.showGolferDetails}
            togglePrevPicksOpen = {this.togglePrevPicksOpen}/>

          <LeagueContestLeaderboard activeLeague={this.props.activeLeague}
            leagueContest={this.state.leagueContest}
            toggleLoading={this.toggleLoading} />

          <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
      </>
    );
  }
}

const Header = (props) => {
  return <Row noGutters className="pt-0 d-flex justify-content-center ml-2">
    <Col className="d-flex justify-content-between">
      <div className="d-flex justify-content-start">
          {/*<h3 className="my-2" style={{fontWeight:'bolder'}}>{this.state.leagueContests.name}</h3>*/}
          <h3 className="my-2" style={{fontWeight:'bolder'}}>{'One-And-Done'}</h3>
      </div>
      <div className="d-flex justify-content-end mr-3">
          <Button outline size="sm" color="primary" className="my-2" onClick={props.toggleShowAllLeagueConest}>
          Back
          </Button>
      </div>
    </Col>
  </Row>
}

const BalanceWarning = ({activeLeague, balancewarning}) => {
  if (!balancewarning ) {
    return null;
  }
  return (
    <Row noGutters className="d-flex justify-content-center mt-4">
      <Col>
        <LeagueBalanceWarning activeLeague={activeLeague} userWarn={false}/>
      </Col>
    </Row>
  )
}

const OADAlert = (props) => {
  return props.oadMsg !== undefined && props.oadMsg &&
      <Row noGutters className="d-flex justify-content-center mt-2">
        <Col lg="12">
          <Alert color="success" className="my-0">
            <strong>{'Commissioner Announcement'}</strong>
	          <div>
              <Linkify properties={{target: '_blank'}}>
                <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>{props.oadMsg}</span>
              </Linkify>
	          </div>
          </Alert>
        </Col>
      </Row>
}

const NextEvent = (props) => {
  if (!props.leagueContest){
    return null;
  }

  if (!props.leagueContest.nextEvent) {
    return (
      <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex justify-content-start pt-1">
            {'Next Event'}
          </div>
          <div className="d-flex justify-content-end mr-2">
            <EventScheduleModal events={props.leagueContest.events}/>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <tbody style={{fontSize: ".9em"}}>
              <td>
                <CardSubtitle>No Events Scheduled</CardSubtitle>
                <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
                  <span>This league has no scheduled events for the rest of this season.</span>
                </CardText>
              </td>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>);
  }

  const eventStatus = ["round1", "round2", "round3", "round4"].indexOf(props.leagueContest.nextEvent.status) > -1 ?
    'live' : props.leagueContest.nextEvent.status;
  return (
    <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex justify-content-start pt-1">
            {eventStatus === 'live'?'Live Event':'Next Event'}
          </div>
          <div className="d-flex justify-content-end mr-2">
            <EventScheduleModal events={props.leagueContest.events}/>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <tbody style={{fontSize: ".9em"}} >
            <tr>
              <td className="mt-4">
                <div className="d-flex justify-content-between">
                  <CardSubtitle className="mt-1">{props.leagueContest.nextEvent.name}
                  { props.leagueContest.nextEvent.status === "scheduled" &&
                    <Badge color="success" style={{marginLeft: "0.5rem"}}>Scheduled</Badge>
                  }
                  { props.leagueContest.nextEvent.status === "open" &&
                    <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
                  }
                  { eventStatus === 'live' &&
                    <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
                  }
                  </CardSubtitle>
                  <div className="d-flex justify-content-end mt-2 mr-2">
                    <Link to={`/onedonerules`} style={{fontSize: "0.77rem"}}>How to Play</Link>
                  </div>
                </div>
                <CardText className="d-flex align-items-center mb-1" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
                  <span className="pr-1">
                    {props.leagueContest.nextEvent.dates} &bull; {props.leagueContest.nextEvent.course}.
                  </span>
                </CardText>
                <div className="d-flex justify-content-between">
                  <div className="">
                    <CardText className="d-flex align-items-center mb-1" style={{"color": "#aaa", "fontSize": "0.70rem"}}>
                      <span className="pr-1">
                        Purse: ${numeral(props.leagueContest.nextEvent.purse).format('0,0')}
                      </span>
                    </CardText>
                    <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.70rem"}}>
                      <span className="pr-1">
                        Defending Champion: {props.leagueContest.nextEvent.defending_champ}
                      </span>
                    </CardText>
                  </div>
                  {(props.leagueContest.nextEvent.noMoreTeam && ["round1", "round2", "round3", "round4"].indexOf(props.leagueContest.nextEvent.status)>-1) &&
                  <div className="d-flex justify-content-end align-items-top mr-2">
                    <div>
                      <Button color="success" className="ml-auto text-nowrap">
                        <Link to={{pathname: "/league/" + props.activeLeague + "/odcontest/event/"+props.leagueContest.nextEvent.eventid, state: { from: "/league/" + props.activeLeague + "/odcontest" }}}
                          style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                          {isMobPortrait()?'Leaderboard':'Live Leaderboard'}
                        </Link>
                      </Button>
                    </div>
                  </div>
                  }
                </div>

                {(props.leagueContest.nextEvent.status === 'scheduled' || !props.leagueContest.nextEvent.availableGolfers || props.leagueContest.nextEvent.availableGolfers.length === 0 ) &&
                  <>
                  <CardText className="d-flex align-items-center">
                    <span className="pr-1 pt-1">
                      {'Field has not yet been finalized. Check back soon.'}
                    </span>
                  </CardText>
                  {(!props.leagueContest.nextEvent.noMoreTeam &&
                    props.leagueContest.nextEvent.teams.length < props.leagueContest.maxTeamsPerUser &&
                    props.leagueContest.nextEvent.allTeams.length === 0) &&
                    <div className="my-2 mb-3 text-center">
                      <Button color="primary" size="sm" className="text-nowrap mr-2"
                      onClick={()=>props.addTeamEntry(true)}>
                      {'Enter Contest'}
                      </Button>
                    </div>
                  }
                  </>
                }

              </td>
            </tr>
            </tbody>
          </Table>
          {props.error &&
          <div className="text-center text-danger">{props.error}</div>
          }
          <GolferTeam activeLeague={props.activeLeague}
            leagueContest={props.leagueContest}
            selectGolfer={props.selectGolfer}
            showGolferDetails={props.showGolferDetails}
            saveTeamEntries={props.saveTeamEntries}
            toggleViewFields={props.toggleViewFields}
            addTeamEntry={props.addTeamEntry}/>
        </CardBody>
      </Card>
    </div>
  )
}


const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

const GolferTeam = (props) => {

  if (!props.leagueContest || !props.leagueContest.nextEvent || props.leagueContest.nextEvent.availableGolfers.length === 0){
    return null;
  }

  const nextEvent = props.leagueContest.nextEvent;
  const eventStatus = ["round1", "round2", "round3", "round4"].indexOf(nextEvent.status)>-1?'live':nextEvent.status;
  if (eventStatus === 'closed'){return null;}

  if (nextEvent.noMoreTeam){
  return (
    <div>
      <div className="px-2 d-flex justify-content-between my-2">
        {["open"].indexOf(nextEvent.status)>-1 &&
        <div className="ml-1 card-subtitle">
          {"Your commissioner is not allowing more entries this season."}
        </div>
        }

      </div>
    </div>
  )
  }

  const rows = nextEvent.teams.map((team, tIndx) => {

    const teamRow = team.entries.map((entry, index) => {
      return (
            <GolferRow teamIndex={tIndx} index={index} nextEvent={nextEvent} team={team} entries={team.entries}
              availableGolfers={nextEvent.availableGolfers} allTeams={props.leagueContest.allTeams}
              prevPicks = {props.leagueContest.prevPicks}
              selectGolfer={props.selectGolfer} showGolferDetails={props.showGolferDetails}
              toggleViewFields={props.toggleViewFields}/>
      );
    });
    return(
      <div>
        <div className="d-flex">
          {(props.leagueContest.maxTeamsPerUser > 1 && nextEvent.teams.length > 1) &&
            <div className="font-weight-bold mt-4 mx-2" style={{fontSize:'1.3em'}}>{(team.name)}</div>
          }
          <div>{teamRow}</div>
        </div>
        {tIndx < nextEvent.teams.length-1 &&
          <Row style={{borderTop:"1px solid #ccc"}} className="mx-4 my-1" />
        }
      </div>
    )

  });


  const cls1 = isMobPortrait()?'d-flex justify-content-center':'d-flex justify-content-between';
  return (
    <div>
      <div className="px-2">

          <div className={cls1}>
            <div className="">
              {rows}
            </div>
            {(!isMobPortrait() && eventStatus==='live') &&
              <div className="d-flex justify-content-end align-items-top my-2">
                <div>
                <Button color="success" className="ml-auto text-nowrap mr-2">
                  <Link to={{pathname: "/league/" + props.activeLeague + "/odcontest/event/"+nextEvent.eventid, state: { from: "/league/" + props.activeLeague + "/odcontest" }}}
                    style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                    {'Live Leaderboard'}
                  </Link>
                </Button>

                </div>
              </div>
            }
          </div>
          {(isMobPortrait() && eventStatus==='live') &&
            <div className="my-2 mb-3 text-center">
            <Button color="success" className="ml-auto text-nowrap mr-2">
              <Link to={{pathname: "/league/" + props.activeLeague + "/odcontest/event/"+nextEvent.eventid, state: { from: "/league/" + props.activeLeague + "/odcontest" }}}
                style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                {isMobPortrait()?'Leaderboard':'Live Leaderboard'}
              </Link>
            </Button>
            </div>
          }
          {(nextEvent && nextEvent.status === 'open' && !nextEvent.noMoreTeam &&
            props.leagueContest.nextEvent.teams.length < props.leagueContest.maxTeamsPerUser &&
            props.leagueContest.nextEvent.teams.length === 0) &&
            <div className="my-2 mb-3 text-center">
              <Button color="primary" size="sm" className="text-nowrap mr-2" onClick={props.addTeamEntry}>
              {'Enter Contest'}
              </Button>
            </div>
          }
          {(nextEvent && nextEvent.status === 'open' && !nextEvent.noMoreTeam && props.leagueContest.allowNewTeams &&
            props.leagueContest.nextEvent.teams.length < props.leagueContest.maxTeamsPerUser &&
            props.leagueContest.nextEvent.teams.length > 0) &&
            <div className="my-2 mb-3 text-center">
              <Button color="primary" size="sm" className="text-nowrap mr-2" onClick={props.addTeamEntry}>
              {'Add Another Entry'}
              </Button>
            </div>
          }
        </div>
    </div>
  )
}

class GolferRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageVerified: false,
      grpGCls: 'golfer-headshot-mid mt-n2',
      imgURL: 'https://media.majorschallenge.com/headshots/headshot.png',
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.index === nextProps.index && nextProps.entries[nextProps.index].golferid !== this.props.entries[this.props.index].golferid) {
      this.setState({imageVerified:false});
    }
  }

  render() {
    const availableGolfers = this.props.availableGolfers;
    const golfer = this.props.entries[this.props.index];
    let pick_golfer = this.props.availableGolfers.filter((g)=>{ return g.golferid === (golfer.pickGolferid || golfer.golferid);})[0];
    let pick_alt_golfer = this.props.availableGolfers.filter((g)=>{ return g.golferid === golfer.pickAltGolferid;})[0];
    let golfer_name = '';
    if (pick_golfer){
      golfer.golfer_name = pick_golfer.golfer_name;
      golfer.pick_golfer_name = pick_golfer.golfer_name;
      golfer_name = golfer.golfer_name;
    }
    if (pick_alt_golfer){
      golfer.pick_alt_golfer_name = pick_alt_golfer.golfer_name;
    }
    const eventStatus = ["round1", "round2", "round3", "round4"].indexOf(this.props.nextEvent.status)>-1?'live':this.props.nextEvent.status;
    const isGameLive = ["round1", "round2", "round3", "round4"].indexOf(this.props.nextEvent.status)>-1 ? true : false;
    const altSelected = (golfer.golferid > 0 && golfer.golferid === golfer.pickAltGolferid)? true : false;


    const IMAGE_BASE_URL = 'https://media.majorschallenge.com/headshots/';
    const IMAGE_NO_HEADSHOT = "https://media.majorschallenge.com/headshots/headshot.png";

    if (!this.state.imageVerified){
      this.setState({'imageVerified':true});

      if (golfer.golferid > 0){
        let img_golfer_nm = (isGameLive && altSelected)?golfer.pick_alt_golfer_name :golfer.pick_golfer_name;

        checkIfImageExists(IMAGE_BASE_URL + img_golfer_nm.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          //if (!exists) {this.setState({grpGCls: 'golfer-headshot-big golfer-name-initial'});}
          if (exists) {this.setState({imgURL: IMAGE_BASE_URL + img_golfer_nm.replace(/[\W_]+/g,'').toLowerCase() + '.png'});}
          if (!exists) {this.setState({imgURL: IMAGE_NO_HEADSHOT});}
        });
      }else{
        this.setState({imgURL: IMAGE_NO_HEADSHOT});
      }
    }


    let golferOptions = [],
      tempSelectedGolferIds = [],
      selectedGolferIds = [],
      selectedValue = null;

    let golferWidthMax = 200;
    this.props.nextEvent.teams.forEach((t)=>{
      t.entries.forEach((e)=>{
        if (e.golferid>0){

          let sgolfer = availableGolfers.filter((x)=>{return x.golferid === e.golferid})[0];
          console.log('jjj ' + getTextWidth(sgolfer?sgolfer.golfer_name:100)*1.3);
          golferWidthMax = Math.max(((getTextWidth(sgolfer?sgolfer.golfer_name:100)*1.3)+20),200);
        }
      });
    });

    /*this.props.entries.forEach((e)=>{
      if (e.golferid>0){
        tempSelectedGolferIds.push(e.golferid);
        if (e.pickGolferid && e.pickGolferid>0 && selectedGolferIds.indexOf(e.pickGolferid) <0){selectedGolferIds.push(e.pickGolferid)}
        if (e.pickAltGolferid && e.pickAltGolferid>0 && selectedGolferIds.indexOf(e.pickAltGolferid) <0){selectedGolferIds.push(e.pickAltGolferid)}

      }
    });*/

    this.props.prevPicks.forEach((t)=>{
      //if (t.golfer_id && !t.substitute_ind){
      if (t.golfer_id && this.props.team.name === t.team_name){
        selectedGolferIds.push(t.golfer_id);
      }
      if (t.pick_alt_golfer_id && this.props.team.name === t.team_name && t.e_status !== 'closed'){
        selectedGolferIds.push(t.pick_alt_golfer_id);
      }


    });

    if (availableGolfers){
      availableGolfers.forEach((g, index) => {
        let option = { value: g.golferid,
          isDisabled: (selectedGolferIds.indexOf(g.golferid) > -1 || tempSelectedGolferIds.indexOf(g.golferid) > -1 ? true : false ),
          label: g.golfer_name
        };
        if (g.golferid === golfer.pickGolferid){
          selectedValue = { value: g.golferid,
            label: g.golfer_name
          };
        }
        golferOptions.push(
            option
        );
      });
    }

    let live_golfer_name = selectedValue ? selectedValue.label : 'No Selection';
    let open_golfer_name = selectedValue ? selectedValue.label : 'Golfer ' + (this.props.index+1);

    //let conCls =  'golfer-headshot-container-big';
    //const bgCls = {backgroundImage: 'url(' + IMAGE_BASE_URL + golfer.golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png)'};
    const bgCls = {borderRadius:'0', backgroundImage: 'url(' + this.state.imgURL +')'};

    const golferContWidth = isMobPortrait() && isGameLive ? '190px' : (isMobPortrait() && !isGameLive ? '200px' : (golferWidthMax)+'px');
    const golferNmCls = isMobPortrait() ? 'font-weight-bold' : 'font-weight-bold text-nowrap';
    return (
      <div className="d-flex justify-content-left align-items-center mb-2 py-2 pl-3">
        <div className={''} style={{zIndex:this.props.index}} onClick={() => this.props.showGolferDetails(golfer.golferid)}>
          <div className={this.state.grpGCls} style={bgCls}><div>{''}</div></div>
        </div>

        <div style={{width:golferContWidth}} className="ml-2">

          {(['open','scheduled'].indexOf(eventStatus) > -1) &&
            <>
            <div className={golferNmCls} style={{fontSize:'1.3em'}}>{open_golfer_name}</div>
            {(golfer.golferid > 0 && !golfer.pick_alt_golfer_name) &&
              <div className="text-danger" style={{fontSize:'.8em'}}>{'no alternate selected'}</div>
            }
            {golfer.pick_alt_golfer_name &&
              <div className="text-secondary" style={{fontSize:'.8em'}}>ALT: {golfer.pick_alt_golfer_name}</div>
            }
            </>
          }
          {(isGameLive && !altSelected) &&
          <>
            <div className={golferNmCls} style={{fontSize:'1.3em'}}>{live_golfer_name}</div>
            {(golfer.golferid > 0 && !golfer.pick_alt_golfer_name) &&
              <div className="text-danger" style={{fontSize:'.8em'}}>{'no alternate selected'}</div>
            }
            {golfer.pick_alt_golfer_name &&
              <div className="text-secondary" style={{fontSize:'.8em'}}>ALT: {golfer.pick_alt_golfer_name}</div>
            }
          </>
          }
          {(isGameLive && altSelected) &&
          <>
            <div className={golferNmCls} style={{fontSize:'1.3em'}}>{golfer.pick_alt_golfer_name}</div>
            <div className="text-secondary" style={{fontSize:'.8em'}}>WD: {golfer.pick_golfer_name}</div>
          </>
          }
        </div>
        {(['open','scheduled'].indexOf(eventStatus) > -1) &&
        <>
        {isMobPortrait() &&
          <div className="d-flex">
            {eventStatus==='open' &&
            <Button size="sm" color='success' outline className="ml-1 btn-no-border"
              onClick={(e)=>{this.props.toggleViewFields(e, this.props.teamIndex, this.props.index, selectedGolferIds,'REG');}}
              style={{minWidth:'30px'}}>
              <FontAwesome name="user-plus" size="2x"/>
            </Button>
            }
            {eventStatus==='scheduled' &&
            <Button size="sm" color='secondary' outline className="ml-1 btn-no-border" disabled
              style={{minWidth:'30px'}}>
              <FontAwesome name="user-plus" size="2x"/>
            </Button>
            }
            {(eventStatus==='open' && golfer.golferid > 0) &&
            <Button size="sm" outline className="ml-1 btn-no-border btn-light-red-outline"
              onClick={(e)=>{this.props.toggleViewFields(e, this.props.teamIndex, this.props.index, selectedGolferIds,'ALT');}}
              style={{minWidth:'30px', paddingTop:'10px'}}>
              <FontAwesome name="user-plus" size="2x" style={{paddingTop:'7px'}}/>
              <div style={{fontSize:'0.7em',marginTop:'-3px'}}>ALT</div>
            </Button>
            }
          </div>
        }
        {!isMobPortrait() &&
        <div className="d-flex">
          <div className="mt-1 ml-2">
            {eventStatus==='open' &&
            <Button color='success' size="sm" onClick={(e)=>{this.props.toggleViewFields(e, this.props.teamIndex, this.props.index, selectedGolferIds,'REG');}}>
              Choose Golfer
            </Button>
            }
            {eventStatus==='scheduled' &&
            <Button color='secondary' size="sm" disabled>
              Choose Golfer
            </Button>
            }
          </div>
          {(eventStatus==='open' && golfer.golferid > 0) &&
          <div className="mt-1 ml-2">
            <Button className="btn-light-red" size="sm" onClick={(e)=>{this.props.toggleViewFields(e, this.props.teamIndex, this.props.index, selectedGolferIds,'ALT');}}>
              Choose Alt
            </Button>
          </div>
          }
        </div>
        }
        </>
        }


      </div>
    )

  }
}


const PrevPicks = (props) => {

  if (!props.leagueContest || props.leagueContest.noMoreTeam){
    return null;
  }

  const prevPicks = props.leagueContest.prevPicks;

  const distinctTeamNames = [...new Set(prevPicks.map(item => item.team_name))];

  const prePicksEle = distinctTeamNames.map((team, i) => {
    let totalPicks = 0;
    let rows = prevPicks.filter((a)=>{return a.team_name === team;}).map((entry, index) => {
      totalPicks += parseInt(entry.earnings);
      return (
            <PrevPicksGolferRow index={index} entry={entry} leagueContest={props.leagueContest}
              showGolferDetails={props.showGolferDetails} activeLeague={props.activeLeague}/>
      );
    });
    const head = distinctTeamNames.length === 1? 'Season Earnings ': 'Team ' + team;
    return (
      <>
        <CardSubtitle className="my-3 ml-2 d-flex justify-content-between"
        onClick={() => props.togglePrevPicksOpen(team)}>
          <div>
            <b>{head}:</b> ${numeral(totalPicks).format('0,0')}
          </div>
          <div className="d-flex justify-content-end mr-4 mt-1">
            <FontAwesome size="lg" name={props.isPrevPicksOpen[team] ? "angle-up" : "angle-down"} />
          </div>
        </CardSubtitle>
        {!props.isPrevPicksOpen[team] &&  <div className="border-bottom"></div>}
        <Collapse isOpen={props.isPrevPicksOpen[team]}>
          <Table responsive className="mb-0 border-bottom">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                {props.leagueContest.maxTeamsPerUser > 100 &&
                  <th className="text-center">Team</th>
                }
                <th className=""></th>
                <th>Name</th>
                <th className="text-left">Tournament</th>
                {/*<th className="text-left ">Date</th>*/}
                <th className="text-right pr-2">Place</th>
                <th className="text-right pr-4" style={{width:'140px'}}>Earnings</th>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {rows}
            </tbody>
          </Table>
          </Collapse>
        </>
        )
  });






  return (
    <Card className="my-4">
      <CardHeader className="d-flex justify-content-between" >
        <div className="d-flex justify-content-start pt-1">
          {'My Picks'}
        </div>
        <div className="d-flex justify-content-end">

        </div>
      </CardHeader>


      <CardBody className="p-0">
        {prePicksEle}
      </CardBody>

    </Card>

  )
}

class PrevPicksGolferRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageVerified: false,
      grpGCls: 'golfer-headshot-small mt-n2',
      imgURL: 'https://media.majorschallenge.com/headshots/headshot.png',
    }
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {
    if (this.props.index === nextProps.index && nextProps.entry.golfer_id !== this.props.entry.golfer_id) {
      this.setState({imageVerified:false});
    }
  }

  render() {
    let entry = this.props.entry;
    const IMAGE_BASE_URL = 'https://media.majorschallenge.com/headshots/';
    const IMAGE_NO_HEADSHOT = "https://media.majorschallenge.com/headshots/headshot.png";

    if (!this.state.imageVerified){
      this.setState({'imageVerified':true});

      if (entry.golfer_id){
        checkIfImageExists(IMAGE_BASE_URL + entry.golfer.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (exists) {this.setState({imgURL: IMAGE_BASE_URL + entry.golfer.replace(/[\W_]+/g,'').toLowerCase() + '.png'});}
          if (!exists) {this.setState({imgURL: IMAGE_NO_HEADSHOT});}
        });
      }else{
        this.setState({imgURL: IMAGE_NO_HEADSHOT});
      }
    }


    const bgCls = {borderRadius:'0', backgroundImage: 'url(' + this.state.imgURL +')'};
    return (
      <tr  className="">
        {this.props.leagueContest.maxTeamsPerUser > 100 &&
          <td className="align-middle text-center">
            {entry.team_name}
          </td>
        }
        <td className="text-center">
          <div className={''} style={{zIndex:this.props.index}} onClick={() => this.props.showGolferDetails(entry.golfer_id)}>
            <div className={this.state.grpGCls} style={bgCls}><div>{''}</div></div>
          </div>
        </td>
        <td className="align-middle text-left">
          {entry.golfer}
        </td>
        <td className="align-middle text-nowrap">
          {entry.e_status === 'open' &&
            entry.tournament
          }
          {entry.e_status !== 'open' &&
          <Link to={{pathname: "/league/" + this.props.activeLeague + "/odcontest/event/"+entry.event_id, state: { from: "/league/" + this.props.activeLeague +"/odcontest"}}}>
          {entry.tournament}
          </Link>
          }
        </td>
        {/*
        <td className="text-left">
          {entry.start_date}
        </td>*/}
        <td className="align-middle text-right">
          {entry.place}
        </td>
        <td className="align-middle text-right pr-4">
          <div style={{width: '120px'}}>
            ${numeral(entry.earnings).format('0,0')}
          </div>
        </td>
      </tr>
    )

  }
}

const LeagueContestLeaderboard = (props) => {
  if (!props.leagueContest || props.leagueContest.leaderboard.length === 0) {
    return null;
  }

  let leaderboard = [];
  props.leagueContest.leaderboard.forEach((l) =>{
    let r_data = leaderboard.filter((lx)=>{return lx.user_id === l.user_id && lx.team_name === l.team_name})[0];
    if (!r_data){
      r_data = {
        team_name: l.team_name,
        user_id: l.user_id,
        user_name: l.user_name,
        usersRow: l.usersRow,
        frndsRow: l.frndsRow,
        earnings: 0.0,
        details: []
      }
      leaderboard.push(r_data);
    }
    r_data.earnings += parseFloat(l.earnings);
    r_data.details.push({...l});
  });
  leaderboard.sort((a,b)=>{return b.earnings - a.earnings;});
  leaderboard = assignRankToLeaderboard(leaderboard,'earnings');

  let summaryRows = [];
  leaderboard.forEach((item, index) => {
    summaryRows.push(<LeagueContestLeaderboardRow maxTeamsPerUser={props.leagueContest.maxTeamsPerUser}
      item={item} key={index} index={index} activeLeague={props.activeLeague}
      scoringMethodId={props.leagueContest.scoringMethodId}
       />);
  });

  return (
    <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex justify-content-start pt-1">
            {'Leaderboard'}
          </div>
          <div className="d-flex justify-content-end mr-2">
            <BreakdownModal activeLeague={props.activeLeague}
              leagueContestId={props.leagueContest.leagueContestId}
              toggleLoading={props.toggleLoading}
              nextEvent={props.leagueContest.nextEvent}
              prevPicks={props.leagueContest.prevPicks}/>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-center">POS</th>
                <th className="">Name</th>
                {props.leagueContest.maxTeamsPerUser > 100 &&
                  <th className="text-center">Team</th>
                }
                <th style={{paddingLeft:'45px'}}>{props.leagueContest.scoringMethodId === 2? 'Total Points':'Total Earnings'}</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {summaryRows}
            </tbody>
          </Table>
        </CardBody>
      </Card>
      <div className=" muted mt-n3 mb-4" style={{textAlign:'center', fontSize:'.9rem'}}>
        Choose friends to follow in  <a href={`/members/${props.activeLeague}`}>Members section</a>.
      </div>
    </div>
  )
}

class LeagueContestLeaderboardRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({collapsed: !this.state.collapsed});
  }

  render() {

    let bodyrowclass = "completedEventRowBody";
    if (!this.state.collapsed) {
      bodyrowclass += " expanded";
    }

    let classname = "pgaLeaderboardRow";
    if (this.props.item.usersRow) {
      classname += " personal";
    }else if (this.props.item.frndsRow){
      classname += " others";
    }

    return (
      <>
        <tr  className={classname} onClick={this.toggle} style={{fontSize: ".9rem"}}>
          <td className="text-center" >
            {this.props.item.rank}
          </td>
          <td className=" align-middle" >
            {this.props.item.user_name}
          </td>
          {this.props.maxTeamsPerUser > 100 &&
            <td className=" text-center" >
              {this.props.item.team_name}
            </td>
          }
          <td className="align-middle text-right">
            <div style={{width: '120px'}}>
              {/*this.props.scoringMethodId === 2?'':'$'}{numeral(this.props.item.earnings).format('0,0')*/}
              {this.props.scoringMethodId === 2?numeral(this.props.item.earnings).format('0,0[.]0'):'$'+numeral(this.props.item.earnings).format('0,0')}
            </div>
          </td>
          <td className="align-middle">
            <FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} />
          </td>
        </tr>
        <tr className={bodyrowclass}>
          <td colSpan="6" style={{padding: 0, borderTop: "none"}} className=" align-middle">
            <Collapse isOpen={!this.state.collapsed} className=" mb-1">
              <LeagueContestLeaderboardDetailsRow maxTeamsPerUser={this.props.maxTeamsPerUser}
              details={this.props.item.details}
              activeLeague={this.props.activeLeague}
              scoringMethodId = {this.props.scoringMethodId}/>
            </Collapse>
          </td>
        </tr>
      </>
    );
  }

}

class LeagueContestLeaderboardDetailsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    const rows = this.props.details.map((detail, index) => {
      return (
        <tr key={index} className="">
          {this.props.maxTeamsPerUser >100 &&
            <td className="text-center" >
              {detail.team_name}
            </td>
          }
          <td className="text-nowrap">{detail.golfer}</td>
          <td className="text-nowrap">
            {detail.event_id === 0 ? detail.tournament :<Link to={`/league/`+ this.props.activeLeague + `/odcontest/event/`+ detail.event_id}>{detail.tournament}</Link>}
          </td>
          <td className="text-center">{detail.place}</td>
          <td className="text-right pr-4">
            <div className="mr-4">
            {/*this.props.scoringMethodId === 2?'':'$'}{numeral(detail.earnings).format('0,0[.]00')*/}
            {this.props.scoringMethodId === 2?numeral(detail.earnings).format('0,0[.]0'):'$'+numeral(detail.earnings).format('0,0[.]00')}
            </div>
          </td>
        </tr>
      )
    });

    return (
      <Table className="contestLBDetailsTable" style={{}}>
        <thead>
          <tr className="contestLBDetailsHeader">
            {this.props.maxTeamsPerUser >100 &&
              <td className="text-center">Team</td>
            }
            <th className="text-nowrap">Golfer</th>
            <th className="text-left">Tournament</th>
            <th className="text-center">Place</th>
            <th className="text-right pr-4">
              <div className="mr-4">{this.props.scoringMethodId === 2? 'Points':'Earnings'}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  }
}

class BreakdownModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      breakdown: [],
      gridDataSort: {
        sortByFld: '',
        sortOrder: '',
        sortFunc: null,
        sortFlds: ['wr','first_name__last_name','picked|wr','available|wr'],
        sortIcons: ['sort','sort','sort','sort']
      }
    };

  }

  componentDidMount() {
    //this.fetchBreakdownData(this.props.activeLeague, this.props.leagueContestId);
  }

  componentWillReceiveProps(nextProps) {
    /*if (nextProps.activeLeague !== this.props.activeLeague ||
      nextProps.leagueContestId !== this.props.leagueContestId) {
      this.fetchBreakdownData(nextProps.activeLeague, nextProps.leagueContestId);
    }*/
  }

  toggle = (event) => {
    if (!this.state.modal && this.state.breakdown.length === 0){
      this.fetchBreakdownData(this.props.activeLeague, this.props.leagueContestId);
    }
    this.setState({
      modal: !this.state.modal,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }

  fetchBreakdownData = (league, leagueContestId) => {
    this.props.toggleLoading(true);
    get_leagueContestOADBreakdown(league, leagueContestId,
     ({success, error, breakdown}) => {
       this.props.toggleLoading(false);
       if (success){
         this.sortBy('wr');
         this.setState({breakdown});
       }

    }, (error) => {
      console.log(error);
    });
  }

  sortBy = (sortByFld) =>{
    let sortFunc = null;
    let gridDataSort = this.state.gridDataSort;
    gridDataSort.sortOrder = (sortByFld === gridDataSort.sortByFld && gridDataSort.sortOrder === 'up')?'down':'up';
    sortFunc = (x,y) => {
      let gdSort = this.state.gridDataSort;
      let a = x, b = y;

      let sortFlds_0 = gdSort.sortByFld.split('|');
      let sortDecision = 0;
      for (var i0=0; i0< sortFlds_0.length; i0++){
        if (gdSort.sortOrder === 'down' && i0===0){
          a = y;
          b = x;
        }else{
          a = x;
          b = y;
        }
        let f0 = sortFlds_0[i0];
        const sortFlds = f0.split('__');
        let a_val = null, b_val = null;
        if (sortFlds.length > 1){
          a_val = ''; b_val = '';
          sortFlds.forEach((f, i) => {
            a_val += a[f] + ' ';
            b_val += b[f] + ' ';
          });

        }else{
          a_val = a[f0];
          b_val = b[f0];
          try{a_val=parseInt(a_val);}catch(e){a_val = a[f0];}
          try{b_val=parseInt(b_val);}catch(e){b_val = b[f0];}
        }
        //if (i0===0){
          sortDecision = (a_val === b_val) ? 0 : (a_val < b_val ? -1 : 1);
        //}else{
        //  sortDecision = (a_val === b_val) ? 0 : (a_val < b_val ? 1 : -1);
        //}
        if (sortDecision !== 0){break;}
      }

      return sortDecision;
    }
    gridDataSort.sortByFld = sortByFld;
    gridDataSort.sortFunc = sortFunc;
    gridDataSort.sortIcons.forEach((item, i) => {
      let ps = gridDataSort.sortFlds.indexOf(sortByFld);
      gridDataSort.sortIcons[i] = (i===ps)?"sort-"+ gridDataSort.sortOrder:'';
    });

    this.setState({gridDataSort});
  }

  render() {
    let selectedGolferIds = []
    if (this.props.nextEvent){
      this.props.nextEvent.allTeams.forEach((t)=>{
        t.entries.forEach((e)=>{
          if (e.golferid>0){selectedGolferIds.push(e.golferid)}
          if (e.pickGolferid && e.pickGolferid>0 && selectedGolferIds.indexOf(e.pickGolferid) <0){selectedGolferIds.push(e.pickGolferid)}
          if (e.pickAltGolferid && e.pickAltGolferid>0 && selectedGolferIds.indexOf(e.pickAltGolferid) <0){selectedGolferIds.push(e.pickAltGolferid)}
        });
      });
    }
    this.props.prevPicks.forEach((e)=>{
        if (e.golfer_id && selectedGolferIds.indexOf(e.golfer_id) <0){selectedGolferIds.push(e.golfer_id)}
    });

    const rows = [];
    let breakdown = this.state.breakdown;
    if (this.state.gridDataSort.sortFunc !== null) {
      breakdown.sort(this.state.gridDataSort.sortFunc);
    }

    breakdown.forEach((item, index) => {
        //let cls = selectedGolferIds.indexOf(item.golfer_id) > -1 ?'text-danger text-left pl-1': 'text-left pl-1';
        let cls = 'text-left pl-1';
        rows.push(
            <tr className="d-flex">
              <td className="text-center" style={{width:'19%'}}>{item.world_rank}</td>
              <td className={cls} style={{width:'42%'}}>{item.first_name + ' ' + item.last_name}</td>
              <td className="text-center pl-4" style={{width:'22%'}}>{item.picked}</td>
              <td className="text-center" style={{width:'32%'}}>{item.available}</td>
            </tr>

        );
    });

    const cls1 = isMobPortrait()?'text-center text-wrap':'text-center text-nowrap';
    const gridDataSort = this.state.gridDataSort;
    return (
      <>
      <Button outline color="primary" size="sm" onClick={this.toggle} className="btn-no-border">
        Breakdown
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="golferModal" autoFocus={false} >
          <ModalHeader toggle={this.toggle} className="mcModalHeader">Breakdown</ModalHeader>
          <ModalBody>
              <Row className="profileFormRow pb-3 pb-sm-0 mt-n1">
                <Col  className="">
                  <Table responsive className="table table-border table-head-fixed">
                    <thead>
                      <tr className="d-flex" style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
                        <th className="text-center" style={{width:'15%'}} onClick={() => {this.sortBy('wr')}}>
                          <div>
                            Rank <FontAwesome size="xl" className="" name={gridDataSort.sortIcons[0]} />
                          </div>
                        </th>
                        <th className="text-left" style={{width:'38%'}} onClick={() => {this.sortBy('first_name__last_name')}}>Golfer <FontAwesome size="lg" className="" name={gridDataSort.sortIcons[1]} /></th>
                        <th className={cls1} style={{width:'22%'}} onClick={() => {this.sortBy('picked|wr')}}>Picked By <FontAwesome size="lg" className="" name={gridDataSort.sortIcons[2]} /></th>
                        <th className={cls1} style={{width:'22%'}} onClick={() => {this.sortBy('available|wr')}}>Available To <FontAwesome size="lg" className="" name={gridDataSort.sortIcons[3]} /></th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize:'13px', borderBottom:'1px solid #dee2e6', height:'500px'}}>
                      {rows}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {/*
              <Row className="pb-3 justify-content-center">
                <div className="text-danger" style={{fontSize:'.85em'}}>{'Your Golfers In Red.'}</div>
              </Row>*/}
          </ModalBody>
        </Modal>
      </Button>
      </>
    );
  }
}

class EventScheduleModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      breakdown: [],
    };
  }

  componentDidMount() {

  }

  componentWillReceiveProps(nextProps) {

  }

  toggle = (event) => {
    this.setState({
      modal: !this.state.modal,
    });

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

  }



  render() {

    const rows = [];
    this.props.events.forEach((item, index) => {
        let cls = 'text-left text-nowrap';
        let trCss = {};
        let mobPurseColor = '#A9A9A9';
        if (item.status && (item.status === 'open' || item.status.indexOf('round')>-1)){
          cls +='font-weight-bold';
          trCss={backgroundColor:'#DAF7A6', color: '#006400', fontWeight:'600'};
          mobPurseColor = '#29a329';
        }else if (item.status === 'closed'){
          trCss={backgroundColor:'rgb(246, 246, 246)', color: '#808080'};
        }
        rows.push(
            <tr className={isMobPortrait()?'d-flex':'d-flex'} style={trCss}>
              <td className={cls} style={isMobPortrait()?{width:'50%'}:{width:'40%'}}>
                {item.tournament_name}
                {isMobPortrait() && <div style={{fontSize:'.8em', color:mobPurseColor}}>${numeral(item.purse).format('0,0')}</div>
                }
              </td>
              {!isMobPortrait() &&
                <td className='text-right' style={{width:'25%'}}>${numeral(item.purse).format('0,0')}</td>
              }
              <td className={isMobPortrait()?'text-left':'text-left text-nowrap'} style={{width:'20%'}}>{item.defending_champ}</td>
              <td className='text-center text-nowrap' style={isMobPortrait()?{width:'30%'}:{width:'20%'}}>{item.start_date}</td>
            </tr>
        );
    });

    return (
      <>
      <Button outline color="primary" size="sm" onClick={this.toggle} className="btn-no-border">
        Full Schedule
        <Modal isOpen={this.state.modal} toggle={this.toggle} backdrop="static" className={isMobPortrait()?'oadFullScheduleMob':'oadFullScheduleDesktop'} autoFocus={true} >
          <ModalHeader toggle={this.toggle} className="mcModalHeader">Full Schedule</ModalHeader>
          <ModalBody>
              <Row className="profileFormRow pb-3 pb-sm-0 mt-n1">
                <Col  className="">
                  <Table responsive className="table table-border table-head-fixed">
                    <thead>
                      <tr className={isMobPortrait()?'d-flex':'d-flex'} style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
                        <th className="text-left" style={isMobPortrait()?{width:'50%'}:{width:'40%'}}>Tournament</th>
                        {!isMobPortrait() &&
                          <th className='text-right mr-4' style={{width:'25%'}}>Purse</th>
                        }

                        <th className={isMobPortrait()?'text-left':'text-left text-nowrap'} style={{width:'20%'}}>Defending Champ</th>
                        <th className={isMobPortrait()?'text-center':'text-center text-nowrap mr-3'} style={isMobPortrait()?{width:'30%'}:{width:'20%'}}>Start Date</th>
                      </tr>
                    </thead>
                    <tbody style={{fontSize:'13px', borderBottom:'1px solid #dee2e6', borderRight:'1px solid #dee2e6', height:'500px'}}>
                      {rows}
                    </tbody>
                  </Table>
                </Col>
              </Row>
          </ModalBody>
        </Modal>
      </Button>
      </>
    );
  }
}
