import React from 'react';
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Card,
  Input,
  Row, Col,
  Table,
  Badge,
} from 'reactstrap';

import {
  Link
} from 'react-router-dom';

import { DateTime } from 'luxon';

import FontAwesome from 'react-fontawesome';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';

import PastEntries from 'common/PastEntries.js';

import {
  get_member_profile,
  set_member_status,
  add_member_note,
  set_alt_commissioner,
  set_member_profile_fld_value,
  start_password_reset,
} from 'utils/api.js';

import 'pages/user/Home.css';

export default class MemberProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsOpen: false,
      profile: null,
      message: '',
      altCommisToolTip: false,
    }

    this.handleChange = this.handleChange.bind(this);
    this.fetch = this.fetch.bind(this);
    this.toggleActions = this.toggleActions.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.markActive = this.markActive.bind(this);
    this.markInactive = this.markInactive.bind(this);
    this.ban = this.ban.bind(this);
    this.addNote = this.addNote.bind(this);
    this.setAlternateCommissioner = this.setAlternateCommissioner.bind(this);
    this.toggleAltCommisTooltip = this.toggleAltCommisTooltip.bind(this);
  }

  toggleAltCommisTooltip(){
    this.setState({ altCommisToolTip: !this.state.altCommisToolTip, });
  }

  toggleActions() {
    this.setState({ actionsOpen: !this.state.actionsOpen, });
  }
  setStatus(status) {
    set_member_status(this.props.activeLeague, this.props.member, status, false,
      ({success, error}) => {
        if (success) {
          this.fetch(this.props.activeLeague, this.props.member);
        } else {
          this.setState({error})
        }
      }, (error) => {
        console.log('Error occurred setting status.')
      }
    )
  }
  markActive() {
    this.setStatus('active');
  }
  markInactive() {
    this.setStatus('inactive');
  }
  ban() {
    this.setStatus('banned');
  }

  componentDidMount() {
    this.fetch(this.props.activeLeague, this.props.member);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague && this.props.member === nextProps.member) {
      return
    }

    this.fetch(nextProps.activeLeague, nextProps.member);
  }

  fetch(league, member) {
    get_member_profile(league, member, ({success, profile, error}) => {
      if (success) {
        this.setState({profile})
      } else {
        this.setState({error})
      }
    }, (error) => {
      this.setState({error: 'There was an unexpected error retrieving this member profile. Please try again later.'});
    });
  }
  addNote() {
    add_member_note(this.props.activeLeague, this.props.member, this.state.message,
      ({success, error}) => {
        if (success) {
          this.setState({message: ''});
          this.fetch(this.props.activeLeague, this.props.member);
        } else {
          this.setState({error})
        }
      }, (error) => {
        console.log('Error occurred setting status.')
      }
    )
  }

  handleChange(event) {
    const message = event.target.value;
    this.setState({ message });
  }

  setAlternateCommissioner(commissionerind){
    //alert(state);
    let profile = this.state.profile;
    profile.commissioner = commissionerind;
    this.setState({ profile });
    set_alt_commissioner(this.props.activeLeague, this.props.member, commissionerind, ({success, error}) => {
      if (success) {
        // nothing
      } else {
        this.setState({error})
      }
    }, (error) => {
      this.setState({error: 'There was an unexpected error retrieving this member profile. Please try again later.'});
    });
  }

  handleFldChange(name, val) {
    let profile = this.state.profile;
    profile[name] = val;
    this.setState({ profile });
    set_member_profile_fld_value(this.props.activeLeague, this.props.member, name, val, ({success, error}) => {
      if (success) {
        // nothing
      } else {
        this.setState({error})
      }
    }, (error) => {
      this.setState({error: 'There was an unexpected error retrieving this member profile. Please try again later.'});
    });
  }

  handleBack = ()=>{
    this.props.history.push('/commissioner/' + this.props.activeLeague +'/members');
  }


  handleResetPassword = ()=>{
    const sendPwd = () =>{
      start_password_reset(this.state.profile.email,
        ({success}) => {
          let msg = '';
          if (success) {
            msg = 'Password reset sent.';
          } else {
            msg = 'Sorry, we did not find an account matching that email.';
          }
          confirmAlert({
            title: 'Password Reset',
            message: msg,
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });
        }, (error) => {
          confirmAlert({
            title: 'Error',
            message: 'An unexpected error occurred. Try again later.',
            buttons: [
              {
                label: 'OK',
                onClick: () => { }
              }
            ]
          });
      });
    }
    let name = (this.state.profile.first_name + ' ' + (this.state.profile.last_name||'')).trim();
    confirmAlert({
      title: 'Password Reset',
      message: 'Send password reset email to ' + name + ' at ' + this.state.profile.email,
      buttons: [
        {
          label: 'Send',
          layoutClass:'button_success',
          onClick: () => { sendPwd();}
        },
        {
          label: 'Cancel',
          layoutClass: 'btn_outline_success',
          onClick: () => { }
        }
      ]
    });


  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <MobileHeader to="Error" leagueid={this.props.activeLeague} />
          <ContentWrapper>
            <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
              {this.state.error}
            </div>
          </ContentWrapper>
        </div>
      )
    } else if (!this.state.profile) {
      return null;
    }

    const profile = this.state.profile;
    const joinDate = DateTime.fromISO(profile.joined ? profile.joined : profile.created_on, { zone: 'UTC' }).setZone('local').toLocaleString();

    const inviteChain = profile.invited_by_chain.map((member, index) => {
      let arrows = [];
      for (let i = 0; i < index; i++) {
        arrows.push(<FontAwesome name="long-arrow-right" key={i} className="mr-2"/>)
      }
      return <div key={index}>{arrows}<Link to={makeMemberUrl(this.props.activeLeague, member.id)}>{member.name}</Link></div>
    });

    const notes = profile.notes.map((note, index) => (
      <li className="mb-2" style={{fontSize: "1rem", fontWeight: 300}} key={index}>
        {DateTime.fromISO(note.created_on, { zone: 'UTC' }).setZone('local').toLocaleString()}: {note.message}
      </li>
    ))

    return (
      <div>
        <MobileHeader to={profile.name} leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <div className='d-flex justify-content-end mr-2 mb-2'
            style={{marginLeft:'20px', color:'blue', cursor: 'pointer'}}
            onClick={this.handleBack}>
            <FontAwesome name="angle-left" size="2x" />
            <div className="backBtnText">Back</div>
          </div>
          <div className="mb-2" style={{fontSize: "1.75em", fontWeight: 300}}>
            {profile.name}
          </div>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {profile.email === ''?'User Name':'Email:'}
            </Col>
            {
              profile.email === '' &&
              <Col sm="8" md="6" className="profileFormRow_content">
                {profile.username}
              </Col>
            }
            {
              profile.email !== '' &&
              <Col sm="8" md="6" className="profileFormRow_content">
                <div className="d-flex">
                  <a href={"mailto:" + profile.email}>{profile.email}</a>
                  <div className="ml-4" onClick={()=>this.handleResetPassword(profile.email)}
                    style={{cursor: 'pointer'}}>
                    <Badge color="primary" className="py-2 px-2">Reset Password</Badge>

                  </div>
                </div>
                { this.state.message && <div className="mt-2" style={{color: "#28a745", fontSize: ".85em"}}> {this.state.message}</div> }
              </Col>
            }
          </Row>
            <Row className="profileFormRow pb-3 pb-sm-0">
              <Col sm="4" md="3" className="profileFormRow_label">
                {'Venmo account:'}
              </Col>
              <Col sm="8" md="6" className="profileFormRow_content">
                {profile.venmo}
              </Col>
            </Row>
            <Row className="profileFormRow pb-3 pb-sm-0">
              <Col sm="4" md="3" className="profileFormRow_label">
                {'Status:'}
              </Col>
              <Col sm="8" md="6" className="profileFormRow_content">
                <ButtonDropdown isOpen={this.state.actionsOpen} toggle={this.toggleActions}>
                  <DropdownToggle caret className="text-uppercase">
                    {profile.status}
                  </DropdownToggle>
                  {
                    profile.commissioner === false &&
                  <DropdownMenu>
                    {profile.status === "active" && <DropdownItem onClick={this.markInactive}>Mark as Inactive</DropdownItem>}
                    {(profile.status === "inactive" || profile.status === "banned") && <DropdownItem onClick={this.markActive}>Mark as Active</DropdownItem>}
                    {profile.status !== "invited" && <DropdownItem divider />}
                    {profile.status !== "invited" && profile.status !== "banned" && <DropdownItem onClick={this.ban}>Ban from League</DropdownItem>}
                    {profile.status === "invited" && <DropdownItem onClick={this.ban}>Remove</DropdownItem>}
                  </DropdownMenu>
                }
                </ButtonDropdown>
              </Col>
            </Row>

         {profile.isleageowner === false &&
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'Alternate Commissioner:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
                <div className="col-sm-6 text-left pl-0">
                  <Bootstrap2Toggle
                    onClick={(state)=>{this.setAlternateCommissioner(state)}}
                    on={'YES'}
                    off={'NO'}
                    size="sm"
                    offstyle="secondary"
                    onstyle="success"
                    active={profile.commissioner}
                  />
                  {/*
                  {profile.isloggeduserleageowner === true &&
                    <Bootstrap2Toggle
                      onClick={(state)=>{this.setAlternateCommissioner(state)}}
                      on={'YES'}
                      off={'NO'}
                      size="sm"
                      offstyle="secondary"
                      onstyle="secondary"
                      active={profile.commissioner}
                    />
                  }
                  {profile.isloggeduserleageowner === false &&
                    <span>{profile.commissioner===true?'Yes':'No'}</span>
                  }
                  */}
                  {/*
                  <span>
                  <a href="#altcommis" id="altCommis">
                     <FontAwesome name="question-circle" className="ml-3" />
                  </a>
                 <Tooltip placement="right" target="altCommis"
                  isOpen={this.state.altCommisToolTip} toggle={this.toggleAltCommisTooltip}>
                    Only the league owner can set alternate commissioner.
                 </Tooltip>
                 </span>
                 */}
                </div>
              </Col>
          </Row>
          }
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'Joined League:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <span>{joinDate}</span>
            </Col>
          </Row>
          {/*
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'Street Address:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <p className="text-uppercase mb-0">{profile.address_line1}</p>
              <p className="text-uppercase mb-0">{profile.address_line2}</p>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'City:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <span className="text-uppercase">{profile.city}</span>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'State:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <span className="text-uppercase">{profile.state}</span>
            </Col>
          </Row>
          <Row className="profileFormRow pb-3 pb-sm-0">
            <Col sm="4" md="3" className="profileFormRow_label">
              {'ZIP:'}
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
              <span className="text-uppercase">{profile.zip}</span>
            </Col>
          </Row>*/}
          {
            inviteChain.length > 0 &&
            <Row className="profileFormRow pb-3 pb-sm-0">
              <Col sm="4" md="3" className="profileFormRow_label">
                {'Invited By:'}
              </Col>
              <Col sm="8" md="6" className="profileFormRow_content">
                {inviteChain}
              </Col>
            </Row>
          }

          <Row className="profileFormRow pb-sm-0">
            <Col sm="4" md="3" className="">
              <div>
              {'Host:'}
              </div>
            </Col>
            <Col sm="8" md="6" className="profileFormRow_content">
                <div className="col-sm-6 text-left pl-0">
                  <Bootstrap2Toggle
                    onClick={(state)=>{this.handleFldChange('host',state)}}
                    on={'Host'}
                    off={'NO'}
                    size="sm"
                    offstyle="secondary"
                    onstyle="success"
                    active={profile.host}
                  />
                </div>
              </Col>
          </Row>
          <Row className="profileFormRow pb-3 mt-0 pb-sm-0 mt-md-n2">
            <Col md="5" className="">
              <div className="" style={{fontSize: ".8rem", color: "#999"}}>
                Switching on Host pins this member at the top of all leaderboards. Use this feature to spark competition between your league and the host.
              </div>
            </Col>
            <Col md="3" className=""></Col>

          </Row>


          <div className="dashboard">
            <div className="d-flex justify-content-left pt-4">
              <Col lg="auto">
                <PastEntries events={profile.entries} embededIn="commissMemberProfile"/>

                <InvitedMembersTable league={this.props.activeLeague} profile={profile} />
              </Col>
            </div>


            <div className="d-flex justify-content-left pt-4">
              <Col lg="auto">
                <div className="mb-2" style={{fontSize: "1.25rem", fontWeight: 300}}>
                  Notes for {profile.first_name + " " + profile.last_name}
                </div>
                <ul>
                  {notes}
                </ul>
                <Input type="textarea" placeholder="New Note Message" value={this.state.message} onChange={this.handleChange} rows="2"/>
                <div className="mt-2">
                  <Button color="primary" onClick={()=>this.addNote()}>
                    {'Add Note'}
                  </Button>
                </div>
              </Col>
            </div>
          </div>
        </ContentWrapper>
      </div>
    )
  }
}

const InvitedMembersTable = ({league, profile}) => {
  if (profile.invitees.length === 0) {
    return null;
  }

  let ownfilterFunc = (invitee) => invitee.email !== 'clubs@majorschallenge.com';
  let invitees = profile.invitees.filter(ownfilterFunc);
  if (invitees.length === 0) {
    return null;
  }

  const rows = invitees.map((invitee, index) => (
    <tr key={index}>
      <td>
        <Link to={makeMemberUrl(league, invitee.id)}>
          <span className="text-nowrap">
            {!!invitee.name ? invitee.name : invitee.email}
          </span>
        </Link>
      </td>
      <td>
        <span className="text-uppercase">
          {invitee.status}
        </span>
      </td>
      <td>
        {invitee.balance}
      </td>
    </tr>
  ));

  return (
    <Card className="p-3 my-4">
      <div className="mb-2" style={{fontSize: "1.25rem", fontWeight: 300}}>
        Members Invited by {profile.first_name + " " + profile.last_name}
      </div>

      <Table responsive>
        <thead>
          <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            <th>Member</th>
            <th>Status</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Card>
  )
}

const makeMemberUrl = (leagueid, memberid) => "/commissioner/" + leagueid + "/member/" + memberid
