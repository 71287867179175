import React from 'react';
import Linkify from 'react-linkify';

import {
  Alert,
  Row, Col,
  Button,
  Card, CardBody, CardHeader, CardSubtitle, CardText,
  Table,
} from 'reactstrap';

import {Link, Redirect} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import numeral from 'numeral';

import { CURRENT_SEASON } from 'utils/constants';

import { NotMobilePortrait, MobilePortrait, isMobPortrait } from 'utils/responsive.js';
import InviteModal from 'common/InviteModal';
import LeagueBalanceWarning from 'common/LeagueBalanceWarning';

import { get_leaguedata, } from 'utils/api.js';
import { get_promo_content } from '../../utils/promoContent.js';

import CupScoringInfoModal from 'common/CupScoringInfoModal';
import MoneyLeaderboard from 'pages/user/MoneyLeaderboard';
import LeagueContestDetails from 'pages/user/LeagueContestDetails';
//import BrandedBanner from 'common/BrandedBanner.js';
import RenderBannerAds from 'common/RenderBannerAds.js';

import 'pages/user/League.css';
import 'pages/user/Cup.css';

import perfectTeamEnter from 'img/250kenter.png';


export default class League extends React.Component {
  constructor(props) {
    super(props);

    this.fetchLeagueData = this.fetchLeagueData.bind(this);
    this.getNewLeagueState = this.getNewLeagueState.bind(this);
    this.setLeagueState = this.setLeagueState.bind(this);
    this.showJoinGolfPool = this.showJoinGolfPool.bind(this);
    this.toggleShowAllLeagueConest = this.toggleShowAllLeagueConest.bind(this);
    this.toggleShowLCLeaderboard = this.toggleShowLCLeaderboard.bind(this);

    this.state = Object.assign(
      this.getNewLeagueState(props),
      {
        nextEvent: { loading: true, },
        cupData: null,
        lastEvent: { loading: true, },
        neverCommissioned: false,
        promo: false,
        isShowJoinGolfPool:false,
        loading: true,
        showAllLeagueConest : props.showAllLeagueConest?props.showAllLeagueConest:false,
        lcContestType:props.lcContestType?props.lcContestType:'SEASON_DRAFT',
        contestOneDone:{nextEvent: null},
        contestSD:{nextEvent: null},
      },
    );
  }

  showJoinGolfPool(){
    this.setState({isShowJoinGolfPool:true,});
  }

  toggleShowAllLeagueConest(contesttype){
    if (this.state.lcEventid){
      this.setState({lcEventid: null, lcLeaguecontestid: null});
      return;
    }
    this.props.history.push('/league/'+this.props.activeLeague);return;
    //this.setState({showAllLeagueConest:!this.state.showAllLeagueConest,lcContestType:contesttype});
  }

  toggleShowLCLeaderboard(lcEventid, lcLeaguecontestid){
    //this.props.history.push('/league/'+this.props.activeLeague+'/sdcontest/event/' + lcEventid);return;
    this.setState({lcEventid, lcLeaguecontestid,});
  }

  getNewLeagueState(props) {
    let league_id = undefined;
    let leagueTitle = undefined;
    let canInvite = false;
    let selectedLeague = null;
    for (var league of props.me.leagues) {
      if (league.leagueid === props.activeLeague) {
        league_id = league.leagueid;
        leagueTitle = league.name;
        canInvite = league.invite_access;
        selectedLeague = league;
        break;
      }
    }

    return {
      leagueid: league_id,
      leagueTitle,
      canInvite,
      showAllLeagueConest: props.showAllLeagueConest?props.showAllLeagueConest:false,
      lcContestType:props.lcContestType?props.lcContestType:'SEASON_DRAFT',
      lcEventid: null,
      lcLeaguecontestid: null,
      selectedLeague: selectedLeague,
      invalidLeagueId: false,

    }
  }

  setLeagueState(props) {
    this.setState(this.getNewLeagueState(props));
  }

  componentDidMount() {
    let paramLeague = parseInt(this.props.match.params.league, 10);

    if (this.props.activeLeague !== paramLeague) {
      this.props.selectLeague(paramLeague);
    } else {
      this.fetchLeagueData(this.props.activeLeague);
    }
    get_promo_content().then(({league}) => {
      this.setState({promocontent: league});
    });
  }

  componentWillReceiveProps(nextProps) {
    let paramLeague = parseInt(nextProps.match.params.league, 10);

    if (nextProps.activeLeague !== paramLeague || nextProps.location.pathname !== this.props.location.pathname) {
      nextProps.selectLeague(paramLeague);
    }

    if (nextProps.activeLeague === this.props.activeLeague && nextProps.location.pathname === this.props.location.pathname) {
      return
    }

    this.setLeagueState(nextProps);
    this.fetchLeagueData(nextProps.activeLeague);
  }

  fetchLeagueData(league) {
    if (!league){this.props.history.push('/');return;}
    get_leaguedata(league,
    ({success, error, ...data}) => {
      if (data && data.leagueid && data.leagueid !== this.props.activeLeague){console.log('wrong leagueid. return');return;}
      if (!success) {

        if (error && error === 'You are not a member of any such league.'){
          this.setState({invalidLeagueId:true});
        }else{
          alert(error);
          this.props.history.push('/');
        }

        return;
      }
      this.setState({
        leagueTitle: data.leaguename,
        leagueOwner: data.leagueowner,
        leagueOwnerEmail: data.leagueowneremail,
        motd: data.motd,
        nextEvent: data.nextEvent,
        cupData: data.cupData,
        lastEvent: data.lastEvent,
        balancewarning: data.balancewarning,
        userBalancewarning: data.userBalancewarning,
        neverCommissioned: data.neverCommissioned,
        promo: data.promo,
        leagueType: data.leagueType,
        contestSDPresent: data.contestSDPresent,
        contestSD: data.contestSD,
        contestOneDonePresent: data.contestOneDonePresent,
        contestOneDone: data.contestOneDone,
        loading: false,
        lbAdvBannerConfigGrp: 'RENDER_ALL_BANNER', //(data.leagueType==='free'?'LB_ADV_BANNER_FREE_LEAGUE':(data.leagueType==='club'?'LB_ADV_BANNER_CLUB_LEAGUE':'LB_ADV_BANNER_CLUB_LEAGUE')),
      });
    }, (error) => {
      console.log(error);
    });
  }


  render() {
    if (this.state.invalidLeagueId){
      return <LeagueInvalid history={this.props.history} />
    }

    const season = CURRENT_SEASON();
    const selectedLeague = this.state.selectedLeague;
    const whiteLabelOn = (selectedLeague && selectedLeague.whiteLabel);
    //const whiteLabelOn = (this.props.me && this.props.me.branding);
    const bnrCls = this.state.motd !== undefined && this.state.motd !== '' ? ' pt-4 mb-n4' : ' pt-4 my-3';
    return (
      <div>
      {!this.state.showAllLeagueConest &&
      <div className="px-lg-4">
        <LeagueTitle {...this.state} {...this.props}/>
        <BalanceWarning {...this.state} {...this.props} />
        {/*<BrandedBanner configGrp={'LB_ADV_BANNER_CLUB_LEAGUE'} leagueId={this.props.activeLeague} textAlign={'left'} settingsType={'LEAGUE_SPECIFIC'} /> */}
        <NotMobilePortrait>
        <Row noGutters className="d-flex justify-content-center">
          <Col lg="7" className="">
            <RenderBannerAds configGrp={'RENDER_ALL_BANNER'} leagueId={this.props.activeLeague} place={'LEAGUE_HOME'} containerClass={bnrCls}/>
          </Col>
        </Row>
        </NotMobilePortrait>
        <MobilePortrait>
        <Row noGutters className="d-flex justify-content-center">
          <Col lg="7" className="">
            <RenderBannerAds configGrp={'RENDER_ALL_BANNER'} leagueId={this.props.activeLeague} place={'LEAGUE_HOME'} containerClass={bnrCls}/>
          </Col>
        </Row>
        </MobilePortrait>

        <LeagueAlert {...this.state} {...this.props}/>
        {this.state.promo && this.state.promocontent &&
          <PromoMessage content={this.state.promocontent} />
        }
        <Row noGutters className="pt-4 d-flex justify-content-center">
          <Col lg="7">
            <NextEvent {...this.state.nextEvent} activeLeague={this.props.activeLeague}
              me={this.props.me} whiteLabelOn={whiteLabelOn}
              contestOneDonePresent={this.state.contestOneDonePresent}
              nextOADEvent={this.state.contestOneDone.nextEvent}
              contestOneDone={this.state.contestOneDone}

              contestSDPresent={this.state.contestSDPresent}
              nextSDEvent={this.state.contestSD.nextEvent}
              contestSD={this.state.contestSD}

              selectedLeague={this.state.selectedLeague}/>
            <LastEvent {...this.state.lastEvent} selectedLeague={this.state.selectedLeague}/>
          </Col>
        </Row>
        { this.state.cupData !== null && this.state.cupData.length !== 0 &&
          <Row noGutters className="pt-4 d-flex justify-content-center">
            <Col lg="7">
              <CupStandings data={this.state.cupData} selectedLeague={this.state.selectedLeague}/>
            </Col>
          </Row>
        }

        { this.state.loading === false &&
          <MoneyLeaderboard activeLeague={this.props.activeLeague}/>
        }
        { /*this.state.neverCommissioned &&
          <Row noGutters className="pt-4 d-flex justify-content-center">
            <Col lg="7">
              <CreateLeaguePromo promo={this.state.promo} isShowJoinGolfPool={this.state.isShowJoinGolfPool} showJoinGolfPool={this.showJoinGolfPool}/>
            </Col>
          </Row>
        */}
        { this.state.contestSDPresent &&
          <Row noGutters className="pt-4 d-flex justify-content-center">
            <Col lg="7">
              <LeagueContests data={this.state.contestSD} activeLeague={this.props.activeLeague}
                toggleShowAllLeagueConest={this.toggleShowAllLeagueConest}/>
            </Col>
          </Row>
        }
        { this.state.contestOneDonePresent &&
          <Row noGutters className="pt-4 d-flex justify-content-center">
            <Col lg="7">
              <LeagueContests data={this.state.contestOneDone} activeLeague={this.props.activeLeague} toggleShowAllLeagueConest={this.toggleShowAllLeagueConest}/>
            </Col>
          </Row>
        }
      { (this.state.loading === false && !whiteLabelOn) &&
        <Row noGutters className="pt-4 d-flex justify-content-center">
          <Col lg="7">
            <CreateLeaguePromo promo={this.state.promo} isShowJoinGolfPool={this.state.isShowJoinGolfPool} showJoinGolfPool={this.showJoinGolfPool}/>
          </Col>
        </Row>
      }
      </div>
      }
      {(this.state.showAllLeagueConest && this.state.contestSD) &&
      <div className="px-lg-4">
        <Row noGutters className="pt-1 d-flex justify-content-center">
          <Col md="8">
              <LeagueContestDetails activeLeague={this.props.activeLeague} season={season} lcEventid={this.state.lcEventid}
              lcLeaguecontestid = {this.state.lcLeaguecontestid}
              contesttype={this.state.lcContestType}
              toggleShowLCLeaderboard={this.toggleShowLCLeaderboard} toggleShowAllLeagueConest={this.toggleShowAllLeagueConest}
              showLB={this.props.showLB} passedEventid={this.props.match.params.event}
              {...this.props}
              />
          </Col>
        </Row>
      </div>
      }
      { (whiteLabelOn) &&
        <Row noGutters className="pt-4 d-flex justify-content-center">
          <Col lg="7" className="pt-4 px-3" style={{fontSize:'12px', textAlign:'center'}}>
            Golf Pool Powered by <Link to={`/`}>Majors Challenge</Link>
          </Col>
        </Row>
      }
      </div>
    );
  }
}

const LeagueInvalid = (props) => (
  <Row noGutters className="pt-2 d-flex justify-content-center">
    <Col lg="7" className="my-1">
      <center>
        <h3>Sorry, you are not a member of this league.<br/><br/> Please check which user you are logged in as.</h3>

        <div className="py-2 d-flex" style={{width:'200px'}}>
          <Button className="" color="primary" size="md"
            onClick={()=>props.history.push('/')} block> {'Dashboard'} </Button>

          <Button className=" ml-3" color="primary" size="md"
          onClick={()=>props.history.push('/profile/settings')} > {'Profile'} </Button>

        </div>
      </center>
    </Col>
  </Row>
)

const maybeLinkWrapper = (link, element) => link
  ? <a href={link} target='_blank' rel="noopener noreferrer">{element}</a>
  : element;

const PromoMessage = ({content}) => {
  return (
    <Row noGutters className="pt-4 d-flex justify-content-center">
      <Col lg="7">
      {maybeLinkWrapper(content.link,
        <div>
          <img className="" src={content.desktop} style={{width: "100%"}} alt="Promo banner."/>
        </div>
      )}

        {/* <Alert color="success" style={{font: "Roboto"}}>
          <h4 className="text-center">This league hosts free promotional contests</h4>
          <h6 className="text-center">Prizing for the current event:</h6>
          <div className="my-3 d-flex justify-content-center">
            <div className="mr-4">
              <div className="font-weight-bold">Place</div>
              <div>
                <span>1</span>
                <span className="align-text-top" style={{fontSize: ".5em"}}>st</span>
              </div>
              <div>
                <span>2</span>
                <span className="align-text-top" style={{fontSize: ".5em"}}>nd</span>
                <span>-5</span>
                <span className="align-text-top" style={{fontSize: ".5em"}}>th</span>
              </div>
            </div>
            <div className="ml-4">
              <div className="font-weight-bold">Prize</div>
              <div>Bushnell Neo Ghost Golf GPS</div>
              <div>Dozen MajorsChallenge golf balls</div>
            </div>
          </div>
          <div style={{fontSize: ".85em"}} className="text-center">* Winning points are for demonstration only</div>
        </Alert> */}
      </Col>
    </Row>
  )
}
const CreateLeaguePromo = (props) => {
  return (
    // <Card body className="p-0">
    //   <center className="mt-3">
    //     <h5>Start your own pool and your first 50 teams are free!</h5>
    //     <Link to="/createleague" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
    //       <Button color="success" className="my-3" size="lg">
    //         Create a League
    //       </Button>
    //     </Link>
    //   </center>
    // </Card>
    <div>
        {/*
        <div className="d-flex justify-content-center">
           <div className="btn-group my-2">
             <Link to="/createleague" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
               <Button color="success" className="font-weight-bold" size="md">
                 Create a Golf Pool
               </Button>
             </Link>
             {props.promo === false &&
             <Button color="success" className="rounded font-weight-bold ml-3" size="md" onClick={() => props.showJoinGolfPool()}>
               Join a Golf Pool
             </Button>
              }
          </div>
        </div>
        */}
        {props.isShowJoinGolfPool &&
          <div>
              <div className="text-center mx-0 my-2" style={{maxWidth:'670px'}}>
              <Link to="/club/free" style={{color: "inherit", textDecoration: "none", cursor: "pointer"}}>
                <img src={perfectTeamEnter} alt="alt" width="100%" />
              </Link>

              <div style={{fontSize:"12px",color:"black", paddingTop:'10px'}}>
                Looking for a friend's pool? Ask them to invite you.
              </div>
            </div>
            {/*
            <div className="text-center mx-3">
              <h3>
                <span style={{color:"#008000"}}> Create a perfect team and WIN $250,000!</span>
              </h3>
              <p className="text-center" style={{color:"#808080"}}>
                <span style={{fontSize:"20px"}}>What's a Perfect Team?</span><br></br>
                <div style={{maxWidth:'650px'}}>
                  A perfect team is a lineup of fantasy golfers with the lowest possible score. It's like a perfect March Madness bracket, but not as difficult. There's no purchase necessary. Just enter our FREE contest, do your research then follow your golfers on the real-time leaderboard.
                </div><br></br>
                <Link to="/club/free" style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                  <Button color="success" outline className="" size="md">
                    Enter To Win $250,000
                  </Button>
                </Link><br></br><br></br>
                <span style={{fontSize:"12px",color:"black"}}>Looking for a friend's pool? Ask them to invite you.</span><br></br>
              </p>
            </div>
          */}
          </div>
        }

    </div>

  )
}

const LeagueTitle = (props) => {
  return (
      <Row className="d-flex justify-content-center px-4">
        <Col lg="7" className="d-flex justify-content-between align-items-start">
          <span>
            <h3 style={{fontWeight:"bolder"}}>{props.leagueTitle}</h3>
            {!props.leagueOwner ? undefined :
            <div style={{color:"#aaa", fontWeight:"200", fontSize: "0.9rem"}}>
              {'Commissioner ' + props.leagueOwner}
            </div>
            }
            {!props.leagueOwnerEmail ? undefined :
            <a href={"mailto:"+props.leagueOwnerEmail}>
              <span style={{whiteSpace: "nowrap", fontSize: "0.85rem"}}>
                <FontAwesome name="envelope" className="mr-2" />
                <span>{props.leagueOwnerEmail}</span>
              </span>
            </a>
            }
          </span>
          { props.canInvite &&
            <span>
              <InviteModal league={ props.activeLeague } />
            </span>
          }
        </Col>
      </Row>
  )
}

const LeagueAlert = (props) => {
  return props.motd !== undefined && props.motd !== '' &&
      <Row noGutters className="d-flex justify-content-center">
        <Col lg="7">
          <Alert color="success" className="my-4">
            <strong>{'Commissioner Announcement'}</strong>
	          <div>
            <Linkify properties={{target: '_blank'}}>
              <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
                <div dangerouslySetInnerHTML={{__html: props.motd}} />
              </span>
            </Linkify>
	           </div>
          </Alert>
        </Col>
      </Row>
}

const BalanceWarning = ({activeLeague, balancewarning, userBalancewarning, ...props}) => {
  if (!balancewarning && !userBalancewarning) {
    return null;
  }
  return (
    <Row noGutters className="d-flex justify-content-center mt-4">
      <Col lg="7">
        <LeagueBalanceWarning activeLeague={activeLeague} userWarn={userBalancewarning}/>
      </Col>
    </Row>
  )
}

const NextEvent = (props) => {
  if (props.loading) {
    return null;
  } else{
    let wkly_ele = <NoNextEvent />;
    let wkly_status = '';
    if (props.status === 'open') {
      wkly_status = 'open';
      wkly_ele = <OpenEvent {...props} gameType={'WEEKLY'}/>
    } else if (['round1', 'round2', 'round3', 'round4'].indexOf(props.status) !== -1) {
      wkly_status = 'live';
      wkly_ele = <LiveEvent {...props} gameType={'WEEKLY'}/>
    } else if (props.status === 'scheduled') {
      wkly_status = 'scheduled';
      wkly_ele = <ScheduledEvent {...props} gameType={'WEEKLY'}/>
    }


    let oad_ele = <NoNextEvent />;
    let oad_status = '';
    if (props.contestOneDonePresent && props.nextOADEvent){
      if (props.nextOADEvent.status === 'open') {
        oad_status = 'open';
        oad_ele = <OpenEvent {...props} {...props.nextOADEvent} gameType={'OAD'}/>
      } else if (['round1', 'round2', 'round3', 'round4'].indexOf(props.nextOADEvent.status) !== -1) {
        oad_status = 'live';
        oad_ele = <LiveEvent {...props} {...props.nextOADEvent} gameType={'OAD'}/>
      } else if (props.nextOADEvent.status === 'scheduled') {
        oad_status = 'scheduled';
        oad_ele = <ScheduledEvent {...props} {...props.nextOADEvent} gameType={'OAD'}/>
      }
    }

    let sd_ele = <NoNextEvent />;
    let sd_status = '';
    if (props.contestSDPresent && props.nextSDEvent){
      if (props.nextSDEvent.status === 'open') {
        sd_status = 'open';
        sd_ele = <OpenEvent {...props} {...props.nextSDEvent} gameType={'SD'}/>
      } else if (['round1', 'round2', 'round3', 'round4'].indexOf(props.nextSDEvent.status) !== -1) {
        sd_status = 'live';
        sd_ele = <LiveEvent {...props} {...props.nextSDEvent} gameType={'SD'}/>
      } else if (props.nextSDEvent.status === 'scheduled') {
        sd_status = 'scheduled';
        sd_ele = <ScheduledEvent {...props} {...props.nextSDEvent} gameType={'SD'}/>
      }
    }

    return (
      <div>
        <Card>
          <CardHeader>{(wkly_status === 'live' || oad_status === 'live' || sd_status === 'live')?'Live':'Next'} Event</CardHeader>
          <CardBody>
            {props.selectedLeague.default_event &&
            <>
            <CardSubtitle className="mb-1 font-weight-bold"  style={{fontSize:'.75em'}}>WEEKLY GOLF POOL</CardSubtitle>
            {wkly_ele}
            </>
            }
            {props.contestOneDonePresent &&
            <>
            <CardSubtitle className={props.selectedLeague.default_event?'mt-3 mb-1 font-weight-bold':'mb-1 font-weight-bold'} style={{fontSize:'.75em'}}>ONE AND DONE</CardSubtitle>
            {oad_ele}
            </>
            }
            {props.contestSDPresent &&
            <>
            <CardSubtitle className={(props.selectedLeague.default_event || props.contestOneDonePresent) ?'mt-3 mb-1 font-weight-bold':'mb-1 font-weight-bold'} style={{fontSize:'.75em'}}>FANTASY DRAFT</CardSubtitle>
            {sd_ele}
            </>
            }
          </CardBody>
        </Card>
      </div>
    );
  }

}

const NoNextEvent = () =>
  <div>
      <CardSubtitle>No Events Scheduled</CardSubtitle>
      <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
        <span>This league has no scheduled events for the rest of this season.</span>
      </CardText>
  </div>

const timeTilLockString = ({lock, perfect_team}) => {
  //RI#183 - LIneup lock message for 250K
  //if (!lock)
  //  return `Lineups lock ${perfect_team ? '2 hours before' : 'at'} first tee off`;
  if (!lock)
    return `Lineups lock at first tee off`;

  let [days, hours, minutes] = lock;

  if (days < 0) {
    return 'Lineups have locked';
  }

  let [dStr, hStr, mStr] = ['','','',''];
  if (days > 0) {
    dStr = days + ' day'+(days===1?'':'s');
  }
  if (hours > 0) {
    hStr = hours + ' hour' +(hours, hours===1?'':'s');
  }
  if (minutes > 0) {
    mStr = minutes + ' minute'+ (minutes, minutes===1?'':'s');
  }

  if (days > 0) {
    return 'Lineups lock in ' + dStr + (hours > 0 ? ', '+hStr : '');
  } else if (hours > 0) {
    return 'Lineups lock in ' + hStr + (minutes > 0 ? ', '+mStr : '');
  } else if (minutes > 0) {
    return 'Lineups lock in ' + mStr;
  } else {
    return 'Lineups lock in under a minute';
  }
}

const OpenEvent = (props) => {
  const [days/*, hours, minutes*/] = props.lock || [];
  return (
      <div>
        <CardSubtitle>{props.title}</CardSubtitle>
        {props.gameType === 'WEEKLY' &&
        <Row>
          <Col xs="6" style={{color: "#aaa", fontSize: "0.77rem", whiteSpace: "nowrap"}}>
            { (!props.isTocFinalEvent && props.club && !props.whiteLabelOn)  && <Link to={`/league/${props.activeLeague}/help`}>How to Play</Link> }
            { (!props.isTocFinalEvent && props.whiteLabelOn)  && <Link to={`/league/${props.activeLeague}/help`}>How to Play</Link> }

            {(props.isTocFinalEvent) && <div style={{color:'#007bff'}} className="font-weight-bold">{'Tournament of Champions'}</div>}
            <div>{props.dates}</div>
            <div>{ timeTilLockString(props) }</div>
            <div>{props.total_teams} teams entered</div>
          </Col>
          <Col  className={isMobPortrait()?'':'d-flex justify-content-end'}>

            {( (days == null || days >= 0) && (!props.isTocFinalEvent || (props.isTocFinalEvent && props.userInToc)) ) &&
            <div className={isMobPortrait()?'d-flex justify-content-end mb-2':'d-flex justify-content-end mr-2'}>
              <Link to={"/events/"+props.eventid}  style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
                <Button color="success" className="btn-sm">
                  {props.user_teams === 0 ? "Enter Your Team" : "Your Teams ("+props.user_teams+")"}
                </Button>
              </Link>
            </div>
            }
            <div className="d-flex justify-content-end">
              <Link to={"/events/"+props.eventid+"/field"}  replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
                <Button color="primary" className="btn-sm">
                  {"View Field"}
                </Button>
              </Link>
            </div>
          </Col>
        </Row>
        }
        {props.gameType === 'OAD' &&
        <Row>
          <Col sm="6" style={{color: "#aaa", fontSize: "0.77rem", whiteSpace: "nowrap"}}>
            <div>{props.dates}</div>
            <div>{ timeTilLockString(props) }</div>
            {props.contestOneDone.noMoreTeam &&
            <div>
              <span style={{ fontSize: "0.77rem", color:'#212529'}} >
                {"Commissioner not allowing new teams."}
              </span>
            </div>
            }
          </Col>
          <Col sm="6" className="d-flex justify-content-end mt-n4">
            {!props.contestOneDone.noMoreTeam &&
            <Link to={`/league/${props.activeLeague}/odcontest`} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
              <Button size="sm" color="success">
                  Pick Golfers
              </Button>
            </Link>
            }
            {props.contestOneDone.noMoreTeam &&
            <div>
            <div className="d-flex justify-content-end">
            <Button size="sm" color="secondary" disabled>
                Locked
            </Button>
            </div>
            </div>
            }
          </Col>
        </Row>
        }

        {props.gameType === 'SD' &&
        <Row>
          <Col sm="6" style={{color: "#aaa", fontSize: "0.77rem", whiteSpace: "nowrap"}}>
            <div>{props.dates}</div>

          </Col>
        </Row>
        }
      </div>
  )
}

const LiveEvent = (props) => {
  return (
    <div>
        <CardSubtitle>{props.title}</CardSubtitle>
        <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
          {props.gameType==='WEEKLY' &&
          <span className="pr-1">
            {props.dates} &bull; You entered {props.user_teams} teams out of {props.total_teams} total.
          </span>
          }
          {props.gameType==='OAD' &&
          <span className="pr-1">
            {props.dates}
          </span>
          }
          {props.gameType==='SD' &&
          <span className="pr-1">
            {props.dates}
          </span>
          }
          <Button color="success" className="ml-auto text-nowrap mt-n4">
            {props.gameType==='WEEKLY' &&
            <Link to={"/events/"+props.eventid} style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
              {isMobPortrait()?'Leaderboard':'Live Leaderboard'}
            </Link>
            }
            {props.gameType==='OAD' &&
            <Link to={{pathname: "/league/" + props.activeLeague + "/odcontest/event/"+props.eventid, state: { from: "/league/" + props.activeLeague + "/odcontest"}}}
              style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
              {isMobPortrait()?'Leaderboard':'Live Leaderboard'}
            </Link>
            }
            {props.gameType==='SD' &&
            <Link to={{pathname: "/league/" + props.activeLeague + "/sdcontest/event/"+props.eventid, state: { from: "/league/" + props.activeLeague + "/sdcontest"}}}
              style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>
              {isMobPortrait()?'Leaderboard':'Live Leaderboard'}
            </Link>
            }
          </Button>
        </CardText>
    </div>
  )
}

const ScheduledEvent = (props) => {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex justify-content-start">
        <div>
        <CardSubtitle>{props.title}</CardSubtitle>
        { (props.gameType === 'WEEKLY' && props.club) && <Link to={`/league/${props.activeLeague}/help`} style={{fontSize: "0.77rem"}}>How to Play</Link> }
        <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
          <span>{props.dates} &bull; Field has not yet been finalized &bull; Check back soon.</span>
        </CardText>
        </div>
      </div>
      {props.gameType === 'OAD' &&
      <div className="d-flex justify-content-end">
        <Link to={"/league/"+props.activeLeague+"/odcontest"} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
          <Button size="sm" color="primary">
              Entries
          </Button>
        </Link>
      </div>

      }
    </div>
  )
}

const CupStandings = (props) => {
  if (props.data.loading) {
    return null;
  }

  const cupRows = props.data.standings
  .filter(({out})=> !out)
  .slice(0,5)
  .map(({owner, team, toPar, behind, rank, usersRow}, index) => {
    const color = (toPar !== null && toPar < 0) ? "#e74c3c" : "#515356";
    const oldCupScoring = (props.data.scoringMethod === 'Gross Score' || props.data.cupScoring2ndPhase === 'OFF')?true:false;
    const content = oldCupScoring?
                  (toPar === 0 ? "E" :
                  toPar === null ?
                    "-" : toPar > 0 ?
                        "+"+toPar : toPar):
                  (toPar === null ?
                            "-" : toPar);

    //const rankClass = 'cupTeamRowRank' + (rank === '1' || rank === 'T1' ? ' winner' : '');
    const rankClass = 'cupTeamRowRank' + (index<props.data.numWinners ? ' winner' : '');

    let showTeam = true;
    if (props.selectedLeague && props.selectedLeague.whiteLabel && !props.selectedLeague.whiteLabel.use_team_names){
      showTeam = false;
    }

    return (
      <tr key={index} className={"cupTeamRowBody" + (usersRow ? ' personal' : '')}>
        <td className="cupTeamRowBase align-middle text-center"><span className={rankClass}>{rank}</span></td>
        <td className="cupTeamRowBase py-1">
          {!showTeam && <div>{owner}</div>}
          {showTeam && <>
          <div>{team}</div>
          <div style={{color: "gray", fontSize: ".8em"}}>{owner}</div>
          </>
          }
        </td>
        <td className="cupTeamRowBase"><span style={{color: color}}>{content}</span></td>
        <td className="cupTeamRowBase">{behind}</td>
      </tr>
    )});

  let showTeam = true;
  if (props.selectedLeague && props.selectedLeague.whiteLabel && !props.selectedLeague.whiteLabel.use_team_names){
    showTeam = false;
  }

  return (
    <div>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            Cup Standings
          </div>
          <div className="d-flex justify-content-end">
            <Link to={"/cup/"+props.data.cupId} style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
              <Button size="sm" color="primary">
                  All Results
              </Button>
            </Link>
          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-center">POS</th>
                <th>{showTeam?'Team (Owner)':'Team Owner'}</th>
                <th className="text-nowrap">{props.data.cupScoring2ndPhase === 'ON' && props.data.scoringMethod !== 'Gross Score'?'Points':'To Par'}
                  {props.data.cupScoring2ndPhase === 'ON' &&
                    <CupScoringInfoModal embedIn={'CommissionerCup'} cupScorePoints={props.data.cupScorePoints} scoringMethod={props.data.scoringMethod}/>
                  }
                </th>
                <th>Behind</th>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {cupRows}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

const LeagueContests = (props) => {
  if (props.data.loading) {
    return null;
  }

  const leagueContestsRows = props.data.leaderboard
  .map((d, index) => {

    return (
      <tr key={index} className={"cupTeamRowBody"}>
        <td className="cupTeamRowBase align-middle text-center">
          <span className=''>{index+1}</span>
        </td>
        <td className="cupTeamRowBase py-1">
          <div>{props.data.contestType === 'SEASON_DRAFT'? d.team_name : d.user_name}</div>
        </td>
        <td className="cupTeamRowBase">
          {props.data.contestType === 'SEASON_DRAFT'? '$'+numeral(d.ytd_earnings).format('0,0'):
            props.data.scoringMethodId === 2?numeral(d.total_earnings).format('0,0[.]0'):'$'+numeral(d.total_earnings).format('0,0')
          }
          {/*${numeral(props.data.contestType === 'SEASON_DRAFT'? d.ytd_earnings : d.total_earnings).format('0,0')}*/}
        </td>
      </tr>
    )});


  return (
    <div>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex justify-content-start">
            {props.data.contestType === 'SEASON_DRAFT'?'Fantasy Draft':'One and Done'}
          </div>
          <div className="d-flex justify-content-end">
            {/*props.data.contestType === 'ONE_AND_DONE' &&
            <Link to={"/league/"+props.activeLeague+"/odcontest"} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto mr-2">
              <Button size="sm" color="success">
                  Make Picks
              </Button>
            </Link>
            */}
            {(props.data.contestType === 'SEASON_DRAFT' && props.data.nextEvent && ['round1', 'round2', 'round3', 'round4'].indexOf(props.data.nextEvent.status) !== -1) &&
            <Link to={{pathname:"/league/"+props.activeLeague+"/sdcontest/event/" + props.data.nextEvent.eventid, state: { from: "/league/" + props.activeLeague + "/sdcontest"}}}
              style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto mr-2">
              <Button size="sm" color="success">
                  Leaderboard
              </Button>
            </Link>


            }
            <Link to={"/league/"+props.activeLeague+(props.data.contestType==="SEASON_DRAFT"?"/sdcontest":"/odcontest")} replace style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
              <Button size="sm" color="primary">
                  All Results
              </Button>
            </Link>


          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-center" style={{width:'15%'}}>POS</th>
                <th style={{width:'55%'}}>{props.data.contestType === 'SEASON_DRAFT'?'Team':'Name'}</th>
                <th >{props.data.contestType === 'SEASON_DRAFT'? 'YTD Earnings':(props.data.scoringMethodId === 2? 'Total Points':'Total Earnings')}</th>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {leagueContestsRows}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

const LastEvent = (props) => {
  if (props.eventid === undefined || props.loading) {
    return null;
  }

  const winning = "You " + (props.winning < 0 ? "lost " : "won ") + numeral( Math.abs(props.winning) ).format('0,0[.]00')

  const wonLost = props.leaderboard.length === 0 ?
    "No teams entered." :
    (props.leagueType !== 'CHARITY' && props.variablepool && winning);

  const pointpool = numeral(props.pointpool).format('0,0[.]00');
  const depositpool = numeral(props.poolDepositAmount).format('0,0[.]00');

  const poolInfo = props.pointpool > 0?
    <span>
      { props.variablepool ? 'Purse:' : 'Purse:' }
      <span className="mcLeaderboardHeaderPointpoolValue ml-1">{pointpool}</span>
    </span> :'';

  const poolInfoDeposit = (props.leagueType === 'CHARITY' && props.charityDisplayAmtRaised)?
      <span className="mcLeaderboardHeaderPointpool">
        {'Amount Raised: '}
        <span className="mcLeaderboardHeaderPointpoolValue ml-1">${depositpool}</span>
      </span>: '';

  return (
    <div className="pt-4">
      <Card>
        <CardHeader>Last Event Summary</CardHeader>
        <CardBody className="p-0">
          <div style={{margin: "20px"}}>
            <CardSubtitle>
              {props.title}
            </CardSubtitle>
            <div className="d-none d-sm-flex justify-content-between flex-wrap align-items-start" style={{"color": "#aaa"}}>
              <div className="mr-auto">
                <span style={{whiteSpace: "nowrap"}}>
                  {props.dates}
                </span>
              </div>
              <div className="mcLeaderboardHeaderPointpool ml-3">
                <span className="mr-3">
                  {wonLost}
                </span>
                {props.leagueType !== 'CHARITY' && props.payoutType !== 'PRIZE' && poolInfo}
                {poolInfoDeposit}
              </div>
              <Link to={"/events/"+props.eventid} style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
                <Button size="sm" color="primary">
                    All Results
                </Button>
              </Link>
            </div>
            <div className="d-sm-none" style={{"color": "#aaa"}}>
              <div>
                <span style={{whiteSpace: "nowrap"}}>
                  {props.dates}
                </span>
              </div>
              <div className="d-flex justify-content-between align-items-end">
                <div className="mcLeaderboardHeaderPointpool">
                  {wonLost &&
                  <span className="mr-3">
                    {wonLost}
                  </span>
                  }
                  {props.leagueType !== 'CHARITY' && props.payoutType !== 'PRIZE' && poolInfo}
                  {poolInfoDeposit}
                </div>
                <Link to={"/events/"+props.eventid} style={{color: "inherit", textDecoration: "none", cursor: "inherit"}} className="ml-auto">
                  <Button size="sm" color="primary">
                      All Results
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <LeaderboardPreview leaderboard={props.leaderboard} payoutType={props.payoutType} leagueType={props.leagueType}
            selectedLeague={props.selectedLeague}/>
        </CardBody>
      </Card>
    </div>
  )
}

const LeaderboardPreview = (props) => {
  const leaderboard = props.leaderboard.map((item) =>
    <LeaderboardPreviewRow key={item.entryId} {...item} leagueType={props.leagueType} payoutType={props.payoutType}
    selectedLeague={props.selectedLeague}/>
  );

  let showTeam = true;
  if (props.selectedLeague && props.selectedLeague.whiteLabel && !props.selectedLeague.whiteLabel.use_team_names){
    showTeam = false;
  }

  return (
    <Table responsive className="mb-0">
      <thead>
        <tr style={{backgroundColor: "#fafafa", color: "gray", fontSize: "0.85rem"}}>
          <th className="text-center">POS</th>
          <th>{showTeam?'Team (Owner)':'Team Owner'}</th>
          <th>Total</th>
          {/*(props.payoutType === 'PRIZE' && props.leagueType !== 'CHARITY') && <th>Prize</th>*/}
          {(props.payoutType !== 'PRIZE' && props.leagueType !== 'CHARITY') && <th>Won</th>}
        </tr>
      </thead>
      {leaderboard}
    </Table>
  );
}

const LeaderboardPreviewRow = (props) => {

  const total = (score) => {
    const color = (score !== null && score < 0) ? "#e74c3c" : "#515356";
    const content = score === 0 ? "E" :
            score === null ?
              "-" : score > 0 ?
                  "+"+score : score;
    return <span style={{color: color}}> {content} </span>
  }

  const winning = (w) => {
    const recolor = w > 0 ? {color: "#27ae60"} : {};
    return  <span style={recolor}>{ numeral(w).format('0,0[.]00') }</span>
  }

  const rank = (rank, winner) => {
    let classname = "mcLeaderboardTeamRowRank";

    if (winner) {
      classname += " winner"
    }

    return <span className={classname}>
      {rank}
    </span>
  }

  let showTeam = true;
  if (props.selectedLeague && props.selectedLeague.whiteLabel && !props.selectedLeague.whiteLabel.use_team_names){
    showTeam = false;
  }

  return (
    <tbody style={{fontSize: ".9em"}}>
      <tr>
        <td className="text-center">{rank(props.rank, props.winner)}</td>
        <td className={!showTeam?"":"py-1"}>
          {!showTeam && <div>{props.owner}</div>}
          {showTeam && <>
          <div className="text-nowrap">{props.team}</div>
          <div className="text-nowrap" style={{color:"gray", fontSize:".8em"}}>{props.owner}</div>
          </>
          }
        </td>
        <td>{total(props.toPar)}</td>
        {(props.payoutType !== 'PRIZE' && props.leagueType !== 'CHARITY') && <td>{winning(props.points)}</td>}
      </tr>
    </tbody>
  )
}
