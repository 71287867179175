import React from 'react';
import {
  Button, ButtonGroup,
  Card,
  Col, Row,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Input, InputGroup, InputGroupAddon,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';


import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";

import {
  enter_event,
  leave_event,
  get_eventsettings,
  set_eventsettings,
} from 'utils/api.js';


import 'pages/commissioner/EnterEditEventModal.css';
import EventRulesModal from 'pages/user/EventRulesModal';


export default class EnterEditEventModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actionsOpen: false,
      modal: false,
      origSettings: {},
      settings: {loading: true,},
      multiplierOptions: ["0.10", "0.25", "0.50", "0.75", "1.00", "Custom"],
      feeOptions: [5,25,50,100,200, "Custom"],
      teams: 10,
      showEstimator: false,
      origWinningPercentages: [],
      origWinningFlat: [],
      success: null,
      message: null
    };

    this.toggle = this.toggle.bind(this);

    // ENTER
    this.handleEnterEvent = this.handleEnterEvent.bind(this);

    // EDIT
    this.toggleActions = this.toggleActions.bind(this);
    this.fetchEventSettings = this.fetchEventSettings.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCupRadio = this.handleCupRadio.bind(this);
    this.handleNoMoreEntriesRadio = this.handleNoMoreEntriesRadio.bind(this);
    this.handleVariableRadio = this.handleVariableRadio.bind(this);
    this.handleMultiplierChange = this.handleMultiplierChange.bind(this);
    this.handleCustomMultiplier = this.handleCustomMultiplier.bind(this);
    this.handleFeeChange = this.handleFeeChange.bind(this);
    this.handleCustomFee = this.handleCustomFee.bind(this);
    this.handleTeams = this.handleTeams.bind(this);
    this.handlePercWinningsChange = this.handlePercWinningsChange.bind(this);
    this.addWinningPercPlace = this.addWinningPercPlace.bind(this);
    this.removeWinningPercPlace = this.removeWinningPercPlace.bind(this);
    this.handleFlatWinningsChange = this.handleFlatWinningsChange.bind(this);
    this.addWinningFlatPlace = this.addWinningFlatPlace.bind(this);
    this.removeWinningFlatPlace = this.removeWinningFlatPlace.bind(this);
    this.handleNoPayoutPrizesChange = this.handleNoPayoutPrizesChange.bind(this);
    this.handlePayoutsRadio = this.handlePayoutsRadio.bind(this);
    this.handleTieRadio = this.handleTieRadio.bind(this);
    this.toggleEstimator = this.toggleEstimator.bind(this);
    this.handleNoBestGolfersScore = this.handleNoBestGolfersScore.bind(this);

    this.stringIsFloat = this.stringIsFloat.bind(this);

    this.handleAddlContstRuleTxt = this.handleAddlContstRuleTxt.bind(this);

    this.handleManageEntries = this.handleManageEntries.bind(this);
    // REMOVE
    this.handleLeaveEvent = this.handleLeaveEvent.bind(this);
    this.leaveEvent = this.leaveEvent.bind(this);
  }

  toggle() {
    if (!this.state.modal) {
      this.fetchEventSettings(this.props.league, this.props.event.id)
    }

    this.setState({
      modal: !this.state.modal,
    });
  }


  handleEnterEvent(eventid) {
    enter_event(this.props.league, eventid,
      ({success, default_event, error}) => {
        if (success) {
          if (!!default_event) { this.props.updateMeDefaultEvent(this.props.league, default_event); }

          this.props.fetchEvents();
        } else {
          console.log(error);
          alert(error);
        }
      },
      (error) =>{
        console.log(error);
        alert(error);
      })
  }

  leaveEvent(event){
    leave_event(this.props.league, event,
      ({success, default_event, error}) => {
        if (success) {
          //if (!!default_event) { this.props.updateMeDefaultEvent(this.props.league, default_event); }
          this.props.updateMeDefaultEvent(this.props.league, default_event);
          this.props.fetchEvents();
        } else {
          alert(error);
        }
      },
      (error) => {
        console.log(error);
        alert("Unexpected error occurred while leaving the event. If the problem persists, please submit the issue via the Feedback page.")
      }
    );
  }

  handleLeaveEvent(event) {
    let confirmationNeeded = false;
    if (this.props.event.status === "open" || ["round1", "round2", "round3", "round4"].indexOf(this.props.event.status) !== -1){
      confirmationNeeded = true;
    }

    if (confirmationNeeded){
      confirmAlert({
        title: 'Confirmation',
        message: 'You are about to remove a live tournament. This is an unrecoverable action. Proceed?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => {
              this.leaveEvent(event);
            }
          },
          {
            label: 'No',
            onClick: () => { }
          }
        ]
      });
    }else{
      this.leaveEvent(event);
    }

  }

  toggleActions() {
    this.setState({ actionsOpen: !this.state.actionsOpen, });
  }

  fetchEventSettings(league, event) {
    get_eventsettings(league, event,
    ({success, settings, error}) => {
      if (success) {

        // Handle options logic
        settings.customMultiplier = "";
        settings.customFee = "";

        if (settings.variableMultiplier === undefined) {
          settings.variableMultiplier = "Custom";
        } else if (!this.state.multiplierOptions.includes( settings.variableMultiplier )) {
          settings.customMultiplier = settings.variableMultiplier;
          settings.variableMultiplier = "Custom";
        }

        if (settings.classicFee === undefined) {
          settings.classicFee = "Custom";
        } else if (this.state.feeOptions.includes( parseInt(settings.classicFee, 10) )) {
          settings.classicFee = parseInt(settings.classicFee, 10);
        } else {
          settings.customFee = settings.classicFee;
          settings.classicFee = "Custom";
        }

        if (settings.maxEntries === 0) {
          settings.maxEntries = "";
        }

        if (settings.maxUserEntries === 0) {
          settings.maxUserEntries = "";
        }

        if (settings.maxLoss === '0.00') {
          settings.maxLoss = "";
        }

        this.setState({
          settings,
          origWinningPercentages: settings.winningPercentages.slice(),
          origWinningFlat: settings.winningFlat.slice(),
          success: null,
          message: null,
          origSettings: JSON.parse(JSON.stringify(settings)),
        });
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }

  handleSubmit(event) {
    let settings = this.state.settings;

    const nMaxEntries = settings.maxEntries !== '' ? settings.maxEntries : 0;
    const oMaxEntries = this.state.origSettings.maxEntries !== '' ? this.state.origSettings.maxEntries : 0;
    if (nMaxEntries > 0 & oMaxEntries >= 0 && nMaxEntries !== oMaxEntries && nMaxEntries < 5){
      confirmAlert({
        title: 'Alert',
        message: 'You have chosen to limit the total entries allowed. This will cap your golf pool at ' + nMaxEntries + ' teams total. Perhaps you instead meant to limit the number of entries per user? See setting for “Maximum teams allowed per user”',
        buttons: [
          {
            label: 'Save Anyway',
            onClick: () => {
              this.saveLeaguesettings(event);
            }
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      })
    }else{
      this.saveLeaguesettings(event);
    }
  }

  saveLeaguesettings = (event) => {
    let settings = this.state.settings;

    let poolFactor;
    if (settings.variablePool) {
      poolFactor = settings.variableMultiplier === "Custom" ? settings.customMultiplier : settings.variableMultiplier;
    } else {
      poolFactor = settings.classicFee === "Custom" ? settings.customFee : settings.classicFee;
    }

    const winningPercentages = settings.winningPercentages.filter(perc => perc !== '');
    let winningFlat = settings.winningFlat.filter(flat => flat !== '');

    if (settings.usePrizeWinnings){
      winningFlat = [];
      for (let i=settings.noPayoutPrizes;i>0;i--){
        winningFlat.push(i+'.00');
      }
    }

    set_eventsettings(
      this.props.league,
      this.props.event.id,
      settings.cup,
      settings.noMoreEntries,
      settings.maxEntries !== '' ? settings.maxEntries : 0,
      settings.maxUserEntries !== '' ? settings.maxUserEntries : 0,
      settings.maxLoss !== '' ? settings.maxLoss : 0.0,
      settings.variablePool,
      poolFactor,
      winningPercentages,
      winningFlat,
      (settings.usePrizeWinnings?true:settings.useFlatWinnings),
      settings.tieBreakRule,
      settings.addlContstRuleTxt,
      (settings.usePrizeWinnings?settings.noPayoutPrizes:0),
      settings.noBestGolfersScore,
      settings.dailyLeaderboard,
      settings.eventHost,
      ({success, error})=>{
        if (success) {

          this.toggle();
          this.props.fetchEvents();
        } else {
          this.setState({success: false, message: error});
        }
      },
      (error)=>{
        this.setState({success: false, message: "An unexpected error occurred while saving the tournament settings. If the problem persists, please contact Majors Challenge."});
      }
    )
  }

  handleChange(event) {
    if ((event.target.name === "maxEntries" || event.target.name === "maxUserEntries" || event.target.name === "maxLoss")
      && event.target.value === "0") {
      event.target.value = "";
    }

    let settings = this.state.settings;
    if (settings[event.target.name] === event.target.value) {
      return;
    }
    settings[event.target.name] = event.target.value;
    this.setState({settings});
  }

  handleNoBestGolfersScore(event){
    let noBestGolfersScore = parseInt(event.target.value);
    let settings = this.state.settings;
    if (settings.noBestGolfersScore === noBestGolfersScore) {
      return;
    }
    settings.noBestGolfersScore = noBestGolfersScore;
    this.setState({settings});
  }

  handleDailyLeaderboard = (daily) => {
    let settings = this.state.settings;
    if (settings.dailyLeaderboard === daily) {
      return;
    }
    settings.dailyLeaderboard = daily;
    this.setState({settings});
  }

  handleEventHostContest = (eventHost) => {
    let settings = this.state.settings;
    if (settings.eventHost === eventHost) {
      return;
    }
    settings.eventHost = eventHost;
    this.setState({settings});
  }

  handleCupRadio(event) {
    let cup = event.target.id === "cupYes";

    let settings = this.state.settings;
    if (settings.cup === cup) {
      return;
    }
    settings.cup = cup;
    this.setState({settings});
  }

  handleNoMoreEntriesRadio(event) {
    let noMoreEntries = event.target.id === "noMoreEntriesYes";

    let settings = this.state.settings;
    if (settings.noMoreEntries === noMoreEntries) {
      return;
    }
    settings.noMoreEntries = noMoreEntries;
    this.setState({settings});
  }

  handleVariableRadio(event) {
    let variablePool = event.target.id === "variablePool";

    let settings = this.state.settings;
    if (settings.variablePool === variablePool) {
      return;
    }
    settings.variablePool = variablePool;
    this.setState({settings});
  }

  handleMultiplierChange(variableMultiplier) {
    let settings = this.state.settings;
    if (settings.variableMultiplier === variableMultiplier) {
      return;
    }
    settings.variableMultiplier = variableMultiplier;
    this.setState({settings});
  }

  handleCustomMultiplier(event) {
    let customMultiplier = event.target.value;

    if (!this.stringIsFloat(customMultiplier)) {
      return;
    }

    let settings = this.state.settings;
    if (settings.customMultiplier === customMultiplier) {
      return;
    }

    settings.customMultiplier = customMultiplier;
    this.setState({settings});
  }

  handleFeeChange(classicFee) {
    let settings = this.state.settings;
    if (settings.classicFee === classicFee) {
      return;
    }
    settings.classicFee = classicFee;
    this.setState({settings});
  }

  handleCustomFee(event) {
    let customFee = event.target.value;

    if (!this.stringIsFloat(customFee)) {
      return;
    }

    let settings = this.state.settings;
    if (settings.customFee === customFee) {
      return;
    }

    settings.customFee = customFee;
    this.setState({settings});
  }

  handleTeams(event) {
    let value = parseInt(event.target.value, 10);
    if (this.state.teams === value) {
      return;
    }
    this.setState({teams: value});
  }

  handlePercWinningsChange(event) {
    let index = event.target.name;
    let value = event.target.value;

    if (!this.stringIsFloat(value)) {
      event.target.value = this.state.winningPercentages;
      return;
    }

    let settings = this.state.settings;

    if (settings.winningPercentages[index] === value) {
      return;
    } else {
      settings.winningPercentages[index] = value;
    }
    this.setState({settings});
  }

  addWinningPercPlace(event) {
    let settings = this.state.settings;
    settings.winningPercentages.push("");
    this.setState({settings});
  }

  removeWinningPercPlace(index) {
    let settings = this.state.settings;
    settings.winningPercentages.splice(index, 1);
    this.setState({settings});
  }

  handleFlatWinningsChange(event) {
    let index = event.target.name;
    let value = event.target.value;

    if (!this.stringIsFloat(value)) {
      event.target.value = this.state.winningFlat;
      return;
    }

    let settings = this.state.settings;

    if (settings.winningFlat[index] === value) {
      return;
    } else {
      settings.winningFlat[index] = value;
    }
    this.setState({settings});
  }

  addWinningFlatPlace(event) {
    let settings = this.state.settings;
    settings.winningFlat.push("");
    this.setState({settings});
  }

  removeWinningFlatPlace(index) {
    let settings = this.state.settings;
    settings.winningFlat.splice(index, 1);
    this.setState({settings});
  }

  handleNoPayoutPrizesChange(event) {
    let value = event.target.value;
    let settings = this.state.settings;
    settings.noPayoutPrizes = value
    this.setState({settings});
  }

  handlePayoutsRadio(event) {
    let settings = this.state.settings;
    if (event.target.id === "prize"){
      settings.useFlatWinnings = false;
      settings.usePrizeWinnings = true;
      settings.noPayoutPrizes = 1;
      this.setState({settings});
      return;
    }
    settings.usePrizeWinnings = false;
    let useFlatWinnings = event.target.id === "flat";
    //if (settings.useFlatWinnings === useFlatWinnings) {
    //  return;
    //}
    settings.useFlatWinnings = useFlatWinnings;
    this.setState({settings});
  }

  handleTieRadio(event) {
    let tieBreakRule = 'MAJORS';
    if (event.target.id === "splittiescut"){tieBreakRule='SPLIT_POINT_CUT';}
    else if (event.target.id === "splittiesnocut"){tieBreakRule='SPLIT_POINT_WITHOUT_CUT';}

    let settings = this.state.settings;
    if (settings.tieBreakRule === tieBreakRule) {
      return;
    }
    settings.tieBreakRule = tieBreakRule;
    this.setState({settings});
  }

  handleAddlContstRuleTxt(event) {
    let addlContstRuleTxt = event.target.value;

    let settings = this.state.settings;
    if (settings.addlContstRuleTxt === addlContstRuleTxt) {
      return;
    }

    settings.addlContstRuleTxt = addlContstRuleTxt;
    this.setState({settings});
  }

  handleManageEntries(eventid){
    this.props.history.push("/commissioner/" + this.props.league + "/payouts/" + eventid);
  }

  toggleEstimator(event) {
    let showEstimator = event.target.name === "showEstimator";
    if (showEstimator === this.state.showEstimator) {
      return;
    }
    this.setState({showEstimator});
  }

  stringIsFloat(value) {
    if (value === "" ) {
      return true;
    }

    let endDot = false;
    if (value.slice(-1) === ".") {
      endDot = true;
      value = value.slice(0,-1);
    }

    if (endDot && value.indexOf('.') !== -1) {
      return false;
    }

    value = parseFloat(value);

    if (Number.isNaN(value)) {
      return false;
    }

    return true;
  }

  render() {
    let contentParams = {...this.state.settings}
    contentParams.multiplierOptions = this.state.multiplierOptions;
    contentParams.feeOptions = this.state.feeOptions;
    contentParams.teams = this.state.teams;
    contentParams.showEstimator = this.state.showEstimator;
    contentParams.origWinningPercentages = this.state.origWinningPercentages;
    contentParams.origWinningFlat = this.state.origWinningFlat;

    contentParams.handleChange = this.handleChange;
    contentParams.handleCupRadio = this.handleCupRadio;
    contentParams.handleNoMoreEntriesRadio = this.handleNoMoreEntriesRadio;
    contentParams.handleInviteRadio = this.handleInviteRadio;
    contentParams.handleVariableRadio = this.handleVariableRadio;
    contentParams.handleMultiplierChange = this.handleMultiplierChange;
    contentParams.handleCustomMultiplier = this.handleCustomMultiplier;
    contentParams.handleFeeChange = this.handleFeeChange;
    contentParams.handleCustomFee = this.handleCustomFee;
    contentParams.handleTeams = this.handleTeams;
    contentParams.handlePercWinningsChange = this.handlePercWinningsChange;
    contentParams.addWinningPercPlace = this.addWinningPercPlace;
    contentParams.removeWinningPercPlace = this.removeWinningPercPlace;
    contentParams.handleFlatWinningsChange = this.handleFlatWinningsChange;
    contentParams.addWinningFlatPlace = this.addWinningFlatPlace;
    contentParams.removeWinningFlatPlace = this.removeWinningFlatPlace;
    contentParams.handlePayoutsRadio = this.handlePayoutsRadio;
    contentParams.handleNoPayoutPrizesChange = this.handleNoPayoutPrizesChange;
    contentParams.handleTieRadio = this.handleTieRadio;
    contentParams.handleAddlContstRuleTxt = this.handleAddlContstRuleTxt;
    contentParams.toggleEstimator = this.toggleEstimator;
    contentParams.handleNoBestGolfersScore = this.handleNoBestGolfersScore;
    contentParams.handleDailyLeaderboard = this.handleDailyLeaderboard;
    contentParams.handleEventHostContest = this.handleEventHostContest;

    let enterButton =
      <Button block color="success" onClick={()=>{this.handleEnterEvent(this.props.event.id)}}
              style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
        Enter
      </Button>

    let editModal =
      <span>
        <Dropdown isOpen={this.state.actionsOpen} toggle={this.toggleActions}>
          <DropdownToggle caret outline color="success" block style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}}>
            Actions
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={this.toggle} style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}}>
              Edit Settings
            </DropdownItem>
            <DropdownItem onClick={ () => {this.handleLeaveEvent(this.props.event.id)}} style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}}>
              Remove
            </DropdownItem>
            <DropdownItem onClick={ () => {this.handleManageEntries(this.props.event.id)}} style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}}>
              Manage Entries
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className="editModal">
          <ModalHeader toggle={this.toggle}>Edit Event Settings</ModalHeader>
          <ModalBody>
            <form onSubmit={this.handleSubmit}>
              <SettingsContent {...contentParams} />
              <input type="submit" value="Save Settings" hidden />
            </form>
          </ModalBody>
          <ModalFooter>
            <Row style={{color: !!this.state.success ? "black" : "red"}} className="m-2">
              {!!this.state.message && this.state.message}
            </Row>
            <Button color="primary" onClick={this.handleSubmit}>Save Settings</Button>
          </ModalFooter>
        </Modal>
      </span>

    return (
      <div>
        { this.props.event.entered ? {...editModal} : {...enterButton} }
      </div>
    )
  }
}

const SettingsContent = (props) => {
  if (props.loading) {
    return null;
  }

  return (
    <Col>
      {/*props.showCup &&
        <div>
          <CupParticipation {...props} />
          <Divider />
        </div>
      */}
      <PreventEntries {...props} />
      <Divider />
      <MaxEntries {...props} />
      {props.poolType !== 'seasonLong' &&
      <>
      <Divider />
      <MaxUserEntries {...props} />
      </>
      }
      {props.variablePool &&
      <>
      <Divider />
      <MaxLoss {...props} />
      </>
      }
      <Divider />
      <LeaderboardCalculationBestScorers {...props} />
      <Divider />
      <PointPool {...props} />
      <Divider />
      <WinningPlaces {...props} />
      <Divider />
      <ContestRules {...props} />
      <Divider />
      <TieBreaker {...props} />
      <Divider />
      <EventHostContest {...props} />
    </Col>
  )
}

const Divider = () => <Row style={{borderTop:"1px solid #ccc"}} className="mt-3 mb-3" />

// const CupParticipation = (props) =>
//   <div>
//     <Row className="contentTitle">
//       Cup Participation
//     </Row>
//     <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
//       {"Include this event in the season-long Cup."}
//     </Row>
//     <Row className="pt-2" noGutters>
//       <Col sm="6">
//         <Input type="radio" name="cup" id="cupYes" className="mr-3" value={!!props.cup} checked={!!props.cup} onChange={props.handleCupRadio} />
//         <span style={{color:"#555"}}> Yes </span>
//       </Col>
//       <Col sm="6">
//         <Input type="radio" name="cup" id="cupNo" className="mr-3" value={!props.cup} checked={!props.cup} onChange={props.handleCupRadio} />
//         <span style={{color:"#555"}}> No </span>
//       </Col>
//     </Row>
//   </div>

const PreventEntries = (props) =>
<div>
  <Row className="contentTitle">
    Allow Additional Entries
  </Row>
  <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
    {"You can prevent users from creating new teams here.  Current entries may still be edited."}
  </Row>
  <Row className="pt-2" noGutters>
    <Col sm="6">
      <Input type="radio" name="noMoreEntries" id="noMoreEntriesNo" className="mr-3" value={!props.noMoreEntries} checked={!props.noMoreEntries} onChange={props.handleNoMoreEntriesRadio} />
      <span style={{color:"#555"}}> Allow </span>
    </Col>
    <Col sm="6">
      <Input type="radio" name="noMoreEntries" id="noMoreEntriesYes" className="mr-3" value={!!props.noMoreEntries} checked={!!props.noMoreEntries} onChange={props.handleNoMoreEntriesRadio} />
      <span style={{color:"#555"}}> Prevent </span>
    </Col>
  </Row>
</div>

const MaxEntries = (props) =>
  <div>
    <Row className="contentTitle">
      {"Limit the number of teams which may enter this event."}
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      Total number of entries allowed in each event. (0 or blank implies No Limit.)
    </Row>
    <Row>
      <Input type="number" placeholder="No Limit" value={props.maxEntries} name="maxEntries" onChange={props.handleChange} />
    </Row>
  </div>

const MaxUserEntries = (props) =>
  <div>
    <Row className="contentTitle">
      {"Limit the number of teams each member may enter into this event."}
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      Total number of entries per member allowed in each event. (0 or blank implies No Limit.)
    </Row>
    <Row>
      <Input type="number" placeholder="No Limit" value={props.maxUserEntries} name="maxUserEntries" onChange={props.handleChange} />
    </Row>
  </div>

const MaxLoss = (props) =>
  <div>
    <Row className="contentTitle">
      Maximum loss per team
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      {"The maximum each entry can lose in a single event. (0 or blank implies No Limit; only applies to Variable point pools.)"}
    </Row>
    <Row>
        <Input type="number" placeholder="No Limit" value={props.maxLoss} name="maxLoss" onChange={props.handleChange} />
    </Row>
  </div>

  const LeaderboardCalculationBestScorers = (props) => {

      return (
        <div>
          <Row className="contentTitle">
            Leaderboard Calculation
          </Row>
          <Row className="pb-2 pt-2" style={{fontSize: ".8rem", color: "#999"}}>
            A teams position on the leaderboard is based on the sum of its golfer scores. How many golfers would you like to count towards each team score? Picking fewer than 12 golfers will drop the worst scores.
          </Row>
          <Row className="pb-2 pt-2" style={{}}>
            <Col sm="12" md="9" style={{paddingLeft:'0px'}}>
              <Row className="pb-2">
                <Col className="d-flex flex-nowrap">
                  <Input type="select" value={props.noBestGolfersScore} name="noBestGolfersScore" id="noBestGolfersScore"
                    className="mx-1" style={{height:"2em"}} onChange={props.handleNoBestGolfersScore}>
                    <option value="12">Use All 12 Golfers</option>
                    <option value="11">Best 11 Scores (drop worst)</option>
                    <option value="10">Best 10 Scores (drop 2 worst)</option>
                    <option value="9">Best 9 Scores (drop 3 worst)</option>
                    <option value="8">Best 8 Scores (drop 4 worst)</option>
                    <option value="7">Best 7 Scores (drop 5 worst)</option>
                  </Input>
                </Col>
              </Row>
            </Col>
          </Row>
          <div class="d-flex flex-nowrap mt-3 ml-n3">
            <div className="ml-0">
              {'Show Daily Leaderboards:'}
            </div>
            <div className="ml-2">
              <Bootstrap2Toggle
                onClick={(state)=>{props.handleDailyLeaderboard(state)}}
                on={'Yes'}
                off={'No'}
                size="sm"
                offstyle="secondary"
                onstyle="success"
                active={props.dailyLeaderboard}/>
            </div>
          </div>
          <Row className="pb-0" style={{fontSize: ".8rem", color: "#999"}}>
            <p>
              Daily leaderboards calculate a new winner each round based on golfer scores for that day only. Existing settings for leaderboard calculation, alternates and tie breakers still apply. Only switch this on if you want to have daily competitions in addition to your regular contest.
            </p>
          </Row>
        </div>
      )
    }


const PointPool = (props) => {
  let validCustomMultiplier = undefined;
  let validMultiplierBorderWidth = "1px";
  let validCustomFee = undefined;
  let validFeeBorderWidth = "1px";

  if (props.customMultiplier !== "") {
    validCustomMultiplier = props.customMultiplier > 0;
  }

  if (props.customFee !== "") {
    validCustomFee = props.customFee > 0;
  }

  return (
    <div>
      <Row className="contentTitle">
        Point Pool
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        How do you want points to be tallied and awarded into a winning purse?
      </Row>
      {props.poolType === 'seasonLong' &&
      <Row className="pt-2 pb-2">
        <Col>
          <p style={{fontSize: ".8rem"}}><b>Your league is configured for a one-time collection. Go to League Settings to change this option.</b></p>
        </Col>
      </Row>
      }


      {props.poolType !== 'seasonLong' &&
      <Row className="pt-2 pb-2">
        <Col>
          <Input type="radio" name="pointpool" id="classicPool" className="mr-3" value={!props.variablePool} checked={!props.variablePool} onChange={props.handleVariableRadio} />
          <span style={{color:"#555"}}>Fixed Entry Amount</span>
          <div style={{fontSize:".85em", color:"#999"}}>
            Each entry owes the same amount towards the tournament point pool.
          </div>
        </Col>
      </Row>
      }
      { (props.poolType !== 'seasonLong' &&  !props.variablePool ) &&
        <div>
          <Row>
            <ButtonGroup className="pb-2 pl-2">
              {
                props.feeOptions.map((item, index) => (
                  <Button className="poolOption" onClick={() => props.handleFeeChange(item)} active={props.classicFee === item} key={index}>
                    {item}
                  </Button>
              ))}
            </ButtonGroup>
          </Row>
          { props.classicFee === "Custom" &&
            <Row className="py-2 d-flex flex-row">
              <Col sm="6">
                <Input type="number" value={props.customFee} valid={validCustomFee} style={{borderWidth: validFeeBorderWidth, fontSize: ".8em"}} onChange={props.handleCustomFee} placeholder="e.g. 10" />
              </Col>
            </Row>
          }
          {/*props.showEstimator ?
            <div>
              <Row>
                <Card body>
                  <ClassicEstimator {...props} />
                  <Row className="mt-3" style={{fontSize:".85rem", color:"#999"}}>
                    <Col>
                      This is an example payout structure with 3 winnings places. You may customize the number of winners, and their winning shares below.
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="hideEstimator" onClick={props.toggleEstimator}>
                  Hide the example payout structure
                </output>
              </Row>
            </div>
            :
            <Row>
              <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="showEstimator" onClick={props.toggleEstimator}>
                View an example payout structure
              </output>
            </Row>
          */}
        </div>
      }

      {props.poolType !== 'seasonLong' &&
      <Row className="pt-2 pb-2">
        <Col>
          <Input type="radio" name="pointpool" id="variablePool" className="mr-3" value={!!props.variablePool} checked={!!props.variablePool} onChange={props.handleVariableRadio} />
          <span style={{color:"#555"}}>Stroke Play</span>
          <div style={{fontSize:".85em", color:"#999"}}>
            {"Stroke play is a variable format similar to stroke play golf. If a team is 10 strokes off the lead then they owe 10 into the purse. Just like golf you don't know your loss until the tournament ends. This format can produce very large purses."}
          </div>
          <div style={{fontSize:".85em", color:"#999", paddingTop:"10px"}}>
      		  How much per stroke would you like to play for?
      		</div>
        </Col>
      </Row>
      }
      {!!props.variablePool &&
        <div>
          <Row>
            <ButtonGroup className="pb-2 pl-2">
              {
                props.multiplierOptions.map((item, index) => (
                  <Button className="poolOption" onClick={() => props.handleMultiplierChange(item)} active={props.variableMultiplier === item} key={index}>
                    {item}
                  </Button>
              ))}
            </ButtonGroup>
          </Row>
          { props.variableMultiplier === "Custom" &&
            <Row className="py-2 d-flex flex-row">
              <Col sm="6">
                <Input type="number" value={props.customMultiplier} valid={validCustomMultiplier} style={{borderWidth: validMultiplierBorderWidth, fontSize: ".8em"}} onChange={props.handleCustomMultiplier} placeholder="e.g. 2.0"/>
              </Col>
            </Row>
          }
          {props.showEstimator ?
            <div>
              <Row>
                <Card body>
                  <VariableEstimator {...props} />
                  <Row className="mt-3" style={{fontSize:".85rem", color:"#999"}}>
                    <Col>
                      This is an example payout structure with 3 winnings places. You may customize the number of winners, and their winning shares below.
                    </Col>
                  </Row>
                </Card>
              </Row>
              <Row>
                <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="hideEstimator" onClick={props.toggleEstimator}>
                  Hide the example payout structure
                </output>
              </Row>
            </div>
            :
            <Row>
              <output style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} name="showEstimator" onClick={props.toggleEstimator}>
                View an example payout structure
              </output>
            </Row>
          }
        </div>
      }

    </div>
  )
}

const VariableEstimator = (props) => {
  let multiplier = props.variableMultiplier === "Custom" ? props.customMultiplier : props.variableMultiplier;
  let points = props.teams * multiplier;

  if (props.teams < 20) {
    points *= 15;
  } else if (props.teams < 50) {
    points *= 30;
  } else {
    points *= 60;
  }

  let pool = numeral(points).format('0,0[.]00');
  let loss = numeral(points/props.teams).format('0,0[.]00')
  let win1 = numeral(65 * points / 100).format('0,0[.]00');
  let win2 = numeral(25 * points / 100).format('0,0[.]00');
  let win3 = numeral(10 * points / 100).format('0,0[.]00');

  return (
    <div style={{color:"#555"}}>
      <Row className="pb-3" style={{fontSize: "1rem", fontWeight: "500"}}>
        <Col> Estimated Projections per Event </Col>
      </Row>
      <TeamCount {...props} />
      <Row noGutters>
        <Col> Estimated point pool </Col>
        <Col> {pool} </Col>
      </Row>
      <Row noGutters>
        <Col> Average loss <span style={{color:"#999"}}>(per team)</span> </Col>
        <Col> {loss} </Col>
      </Row>
      <Row noGutters className="pt-3">
        <Col> {"1st Place"} </Col>
        <Col> {win1} <span style={{color:"#999"}}>(65%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"2nd Place"} </Col>
        <Col> {win2} <span style={{color:"#999"}}>(25%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"3rd Place"} </Col>
        <Col> {win3} <span style={{color:"#999"}}>(10%)</span> </Col>
      </Row>
    </div>
  )
}

/*const ClassicEstimator = (props) => {
  let fee = props.classicFee === "Custom" ? props.customFee : props.classicFee;
  let points = props.teams * fee;
  let pool = numeral(points).format('0,0[.]00');
  let win1 = numeral(65 * points / 100).format('0,0[.]00');
  let win2 = numeral(25 * points / 100).format('0,0[.]00');
  let win3 = numeral(10 * points / 100).format('0,0[.]00');

  return (
    <div style={{color:"#555"}}>
      <Row className="pb-3" style={{fontSize: "1rem", fontWeight: "500"}}>
        <Col> Payout Structure </Col>
      </Row>
      <TeamCount {...props} />
      <Row noGutters className="pt-3">
        <Col> Point pool </Col>
        <Col> {pool} </Col>
      </Row>
      <Row noGutters className="pt-3">
        <Col> {"1st Place"} </Col>
        <Col> {win1} <span style={{color:"#999"}}>(65%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"2nd Place"} </Col>
        <Col> {win2} <span style={{color:"#999"}}>(25%)</span> </Col>
      </Row>
      <Row noGutters>
        <Col> {"3rd Place"} </Col>
        <Col> {win3} <span style={{color:"#999"}}>(10%)</span> </Col>
      </Row>
    </div>
  )
}*/

const TeamCount = (props) =>
  <Row style={{color:"#999"}}>
    <Col className="d-flex">
      <span style={{lineHeight:"2rem"}}> How many teams do you think you will have: </span>
      <Input type="select" value={props.teams} name="entryCount" id="exampleEntryCount" className="ml-1 mr-1" style={{width:"5em", color:"#999", height:"2em"}} onChange={props.handleTeams}>
        <option>4</option>
        <option>6</option>
        <option>8</option>
        <option>10</option>
        <option>15</option>
        <option>20</option>
        <option>30</option>
        <option>40</option>
        <option>50</option>
        <option>75</option>
        <option>100</option>
        <option>150</option>
        <option>200</option>
        <option>300</option>
        <option>400</option>
        <option>500</option>
        <option>600</option>
      </Input>
    </Col>
  </Row>

const WinningPlaces = (props) => (
  <div>
    <Row className="contentTitle">
      Winning Places and Payouts
    </Row>
    {props.poolType !== 'seasonLong' &&
    <Row className="pt-2 pl-2" noGutters>
      <Col>
        <Input type="radio" name="winningPlaces" id="percentage" className="mr-3" value={!props.useFlatWinnings && !props.usePrizeWinnings} checked={!props.useFlatWinnings && !props.usePrizeWinnings} onChange={props.handlePayoutsRadio} />
        <span style={{color:"#555"}}> Payout Percentage of Pool</span>
      </Col>
    </Row>
    }
    <Row className="pl-2" noGutters>
      <Col>
        <Input type="radio" name="winningPlaces" id="flat" className="mr-3" value={!!props.useFlatWinnings && !props.usePrizeWinnings} checked={!!props.useFlatWinnings && !props.usePrizeWinnings} onChange={props.handlePayoutsRadio} />
        <span style={{color:"#555"}}> Payout Fixed Amount</span>
      </Col>
    </Row>
    <Row className="pl-2 mb-2" noGutters>
      <Col>
        <Input type="radio" name="winningPlaces" id="prize" className="mr-3" value={!!props.usePrizeWinnings} checked={!!props.usePrizeWinnings} onChange={props.handlePayoutsRadio} />
        <span style={{color:"#555"}}> Payout in Prizes</span>
      </Col>
    </Row>
    {props.usePrizeWinnings? PrizeWinningPlaces(props) : props.useFlatWinnings ? FlatWinningPlaces(props) : PercWinningPlaces(props)}
  </div>
);

const FlatWinningPlaces = (props) => {
  let total = 0.0;

  for (var flat of props.winningFlat) {
    if (flat === "") {
      continue;
    }

    flat = ""+flat;
    if (flat.slice(-1) === ".") {
      flat = flat.slice(0,-1);
    }

    total += parseFloat(flat);
  }

  function removeEvent(index) {
    return (event) => {
      props.removeWinningFlatPlace(index);
      event.stopPropagation();
      event.preventDefault();
    }
  }

  let removable = props.winningFlat.length > 1;
  let rows = props.winningFlat.map((amount, index) =>
    <Row key={index} className="pt-1">
      <Col xs="10">
        <InputGroup>
          <InputGroupAddon style={{width: "2rem", lineHeight: "2rem"}} addonType="prepend">
            <div>{index+1}</div>
          </InputGroupAddon>
          <InputGroupAddon addonType="prepend">$</InputGroupAddon>
          <Input placeholder="0" value={amount} className="text-center" name={index} onChange={props.handleFlatWinningsChange} type="number" />
        </InputGroup>
      </Col>
      {removable &&
        <Col xs="2">
          <Button className="mt-2 pt-0 pb-0 pl-1 pr-1" color="danger" onClick={ removeEvent(index) } >
            <FontAwesome name="times" />
          </Button>
        </Col>
      }
    </Row>
  )

  let unchanged = props.winningFlat.length === props.origWinningFlat.length &&
                  props.winningFlat.every((curr, index) => { return curr === props.origWinningFlat[ index ]});

  let valid = undefined;
  if (!unchanged) {
    //valid = total <= 100;
    valid = (props.useFlatWinnings?true:(total <= 100)) ;
  }
  let validBorderWidth = valid === undefined ?
                            "1px" :
                            valid === true ?
                              "2px" :
                              "3px";

  return (
    <div>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Fixed payouts award a fixed amount to winning places regardless of collections or how many teams have entered. Use this method to better control winnings for individual tournaments or to allocate collections when choosing one-time season long payment. Fixed payouts is also useful for promotional events where the Fixed Fee is set to $0.
      </Row>
      <Col sm="9" md="6" >
        <Row className="pb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">Total</InputGroupAddon>
            <Input value={ numeral(total).format('$0[.]000') } readOnly valid={valid} invalid={valid===false} style={{borderWidth: validBorderWidth}} />
          </InputGroup>
        </Row>
        {rows}
        <Row className="pt-1">
          <span onClick={props.addWinningFlatPlace} style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} >
            Add Winning Place
          </span>
        </Row>
      </Col>
    </div>
  )
}

const PercWinningPlaces = (props) => {
  let total = 0.0;

  for (var perc of props.winningPercentages) {
    if (perc === "") {
      continue;
    }

    perc = ""+perc;
    if (perc.slice(-1) === ".") {
      perc = perc.slice(0,-1);
    }

    total += parseFloat(perc);
  }

  function removeEvent(index) {
    return (event) => {
      props.removeWinningPercPlace(index);
      event.stopPropagation();
      event.preventDefault();
    }
  }

  let removable = props.winningPercentages.length > 1;
  let rows = props.winningPercentages.map((percent, index) =>
    <Row key={index} className="pt-1">
      <Col xs="10">
        <InputGroup>
          <InputGroupAddon style={{width: "2rem", lineHeight: "2rem"}} addonType="prepend">{index+1}</InputGroupAddon>
          <Input placeholder="0" value={percent} className="text-center" name={index} onChange={props.handlePercWinningsChange} type="number" />
          <InputGroupAddon addonType="append">%</InputGroupAddon>
        </InputGroup>
      </Col>
      {removable &&
        <Col xs="2">
          <Button className="mt-2 pt-0 pb-0 pl-1 pr-1" color="danger" onClick={ removeEvent(index) } >
            <FontAwesome name="times" />
          </Button>
        </Col>
      }
    </Row>
  )

  let unchanged = props.winningPercentages.length === props.origWinningPercentages.length &&
                  props.winningPercentages.every((curr, index) => { return curr === props.origWinningPercentages[ index ]});

  let valid = undefined;
  if (!unchanged) {
    valid = total <= 100;
  }
  let validBorderWidth = valid === undefined ?
                            "1px" :
                            valid === true ?
                              "2px" :
                              "3px";

  return (
    <div>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        We recommend that roughly the top 15% are winning places. It is not necessary to pay out 100% of the purse.
      </Row>
      <Col sm="9" md="6" >
        <Row className="pb-2">
          <InputGroup>
            <InputGroupAddon addonType="prepend">Total</InputGroupAddon>
            <Input value={ numeral(total).format('0[.]000') } readOnly valid={valid} invalid={valid===false} style={{borderWidth: validBorderWidth}} />
            <InputGroupAddon addonType="append">%</InputGroupAddon>
          </InputGroup>
        </Row>
        {rows}
        <Row className="pt-1">
          <span onClick={props.addWinningPercPlace} style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} >
            Add Winning Place
          </span>
        </Row>
      </Col>
    </div>
  )
}
const PrizeWinningPlaces = (props) => {

  return (
    <div>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        Use this option if you plan awarding prizes to the winners. The leaderboard will indicate "prize" instead of an amount. Describe your prize structure or any additional rules in the Contest Rules section below.
      </Row>
      <Col sm="9" md="6" >
        <Row className="pb-2">
          <Col className="d-flex">
            <span style={{lineHeight:"2rem"}}> Winning Places: </span>
            {/*<Input placeholder="0" value={props.noPayoutPrizes} className="text-center" name={index} onChange={props.handleNoPayoutPrizesChange} type="number" />*/}
            <Input type="select" value={props.noPayoutPrizes} name="noPayoutPrizes" id="noPayoutPrizes"
              className="mx-1" style={{width:"4em", color:"#999", height:"2em"}} onChange={props.handleNoPayoutPrizesChange}>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
              <option>7</option>
              <option>8</option>
              <option>9</option>
              <option>10</option>
              <option>11</option>
              <option>12</option>
              <option>13</option>
              <option>14</option>
              <option>15</option>
              <option>16</option>
              <option>17</option>
              <option>18</option>
              <option>19</option>
              <option>20</option>
            </Input>
          </Col>
        </Row>
      </Col>
    </div>
  )
}


const ContestRules = (props) =>{
  let eventRules = <span>Preview</span>
  let eventSettings = props;
  return(
  <div>
    <Row className="contentTitle">
      Contest Rules & Prizes (Optional)
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      Use this section to optionally describe any specific rules for your golf pool. When provided, this text will display when a user clicks Event Rules from the leaderboard.
    </Row>
    <Row className="pt-2 ml-n3" noGutters>
      <Col>
        <Input type="textarea" rows={3} placeholder="Please enter Contest Rules." value={props.addlContstRuleTxt} onChange={props.handleAddlContstRuleTxt} />
      </Col>
    </Row>
    <Row className="mb-3 align-items-center">
      <Col className="d-flex justify-content-end">
        <EventRulesModal eventSettings={eventSettings} component={eventRules} />
      </Col>
    </Row>
  </div>
  )
}

const TieBreaker = (props) =>
  <div>
    <Row className="contentTitle">
      Tie-breaker Rule
    </Row>
    <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
      {"Rosters with tied scores may either use the Majors Challenge tiebreaker rule to attempt to break the tie, or they may split the winnings for the tied places."}
    </Row>
    <Row className="pt-2" noGutters>
      <Col>
        <Input type="radio" name="tieBreaker" id="tiebreak" className="mr-3" value={props.tieBreakRule} checked={props.tieBreakRule==='MAJORS'} onChange={props.handleTieRadio} />
        <span style={{color:"#555"}}> Use Majors Challenge tiebreaker </span>
      </Col>
    </Row>
    <Row noGutters>
      <Col>
        <Input type="radio" name="tieBreaker" id="splittiescut" className="mr-3" value={props.tieBreakRule} checked={props.tieBreakRule==='SPLIT_POINT_CUT'} onChange={props.handleTieRadio} />
        <span style={{color:"#555"}}> Split point pool across tied teams (with cuts) </span>
      </Col>
    </Row>
    <Row noGutters>
      <Col>
        <Input type="radio" name="tieBreaker" id="splittiesnocut" className="mr-3" value={props.tieBreakRule} checked={props.tieBreakRule==='SPLIT_POINT_WITHOUT_CUT'} onChange={props.handleTieRadio} />
        <span style={{color:"#555"}}> Split point pool across tied teams (without cuts) </span>
      </Col>
    </Row>
    <Row className="pt-2" noGutters>
      <Col>
        <a href="/rules" target="_blank">Detailed Rules <FontAwesome name="external-link"/></a>
      </Col>
    </Row>
  </div>

  const EventHostContest = (props) =>
    <div>
      <Row className="contentTitle">
        Beat The Host Contest
      </Row>
      <Row className="pb-2" style={{fontSize: ".8rem", color: "#999"}}>
        {"The Beat the Host contest pins selected members at the top of the leaderboard, creating a competition between them and the golf pool. Assign members as hosts from the Members section."}
      </Row>
      <Row className="pt-2 ml-n3" noGutters>
        <Col>
        <Bootstrap2Toggle
          onClick={(state)=>{props.handleEventHostContest(state)}}
          on={'ACTIVE'}
          off={'OFF'}
          size="sm"
          offstyle="secondary"
          onstyle="success"
          active={props.eventHost}
        />
        </Col>
      </Row>
    </div>
